import { TicketsService } from './../../services/tickets.service';
import { LuckiestAuthService } from './../../services/luckiest-auth.service';
import { ViewService } from 'src/app/services/view/view.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-play-button',
  templateUrl: './play-button.component.html',
  styleUrls: ['./play-button.component.scss'],
})
export class PlayButtonComponent implements OnInit {
  @Input() public playText;
  @Input() public continueText;
  @Input() public showCount;

  constructor(public viewService: ViewService,
    public luckAuth: LuckiestAuthService,
    public ticketService: TicketsService
    ) { }

  ngOnInit() {}

}
