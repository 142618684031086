import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class Config {
  config: {
    name: 'Jama';
    guestAllowed: true;
  };

  defaultUser: any = UserType.serviceConsumer;

  userType = UserType;
  serviceProvider = 'seller';
  serviceConsumer = 'customer';
  serviceItem = 'listing';
  themeSetType = 'system';
  /*singleCategoryModule:any = {
    //title:'Property',
    title:'Cars',
    //uid:'7ee66715-8201-4088-b85d-836a3fe99ccc'
    uid:'163f4aaf-45c8-49fe-9b0c-e0a26c08681e'
  }*/
  singleCategoryModule: any = false;

  public appserviceProvider = [
    {
      title: 'Dashboard',
      url: '/' + this.serviceProvider + '-dashboard/default',
      icon: 'pie-chart',
    },
    {
      title: 'My ' + this.serviceItem + 's',
      url: '/' + this.serviceProvider + '-dashboard/item-list',
      icon: 'list-circle',
    },
    {
      title: 'Add New',
      url: '/create',
      icon: 'add-circle',
    },
    // { title: 'Company', url: '/'+this.serviceProvider+'-dashboard/company-profile', icon: 'cube' },
    { title: 'Chats', url: '/chat-list', icon: 'chatbox' },
    { title: 'Notifcations', url: '/notifications', icon: 'notifications' },
    { title: 'Profile', url: '/account', icon: 'person' },
    {
      title: 'Balance',
      url: '/account-and-transactions',
      icon: 'cash',
      value: '..',
    },
  ];

  public appserviceConsumer = [
    {
      title: 'Dashboard',
      url: '/' + this.serviceConsumer + '-dashboard',
      icon: 'bar-chart',
    },
    {
      title: 'My Favorites',
      url: '/favorites',
      icon: 'heart',
    },
    //{ title: 'Add New', url: '/create', icon: 'add' },
    // { title: 'Company', url: '/'+this.serviceProvider+'-dashboard/company-profile', icon: 'cube' },
    { title: 'Chats', url: '/chat-list', icon: 'chatbox' },
    { title: 'Notifcations', url: '/notifications', icon: 'notifications' },
    //{ title: 'Profile', url: '/account', icon: 'person' },
    //{ title: 'Balance', url: '/account-and-transactions', icon: 'cash',value:'..',}
  ];

  public appServiceAdmin = [
    {
      title: 'Add Sound',
      url: '/' + this.serviceProvider + '-dashboard/default',
      icon: 'analytics',
    },
    {
      title: 'Premium Settings',
      url: '/' + this.serviceProvider + '-dashboard/job-list',
      icon: 'list',
    },
    {
      title: 'stats',
      url: '/' + this.serviceProvider + '-dashboard/add-job',
      icon: 'add',
    },
  ];

  themes: any = [
    {
      name: 'cojja',
      previewColor: '#ed0973',
      styles: [
        { themeVariable: '--ion-color-primary', value: '#00e490' },
        { themeVariable: '--ion-color-primary-rgb', value: '66,140,255' },
        { themeVariable: '--ion-color-primary-contrast', value: '#fdfdfd' },
        {
          themeVariable: '--ion-color-primary-contrast-rgb',
          value: '255,255,255',
        },
        {
          themeVariable: '--ion-color-primary-shade',
          value: 'rgb(247 249 249)',
        },
        { themeVariable: '--ion-color-primary-tint', value: '#5598ff' },

        { themeVariable: '--ion-item-ios-background-color', value: '#ffffff' },
        { themeVariable: '--ion-item-md-background-color', value: '#ffffff' },
        { themeVariable: '--ion-tabbar-background-color', value: '#ffffff' },
        {
          themeVariable: '--ion-tabbar-ios-text-color-active',
          value: '#fa0fa0',
        },
        {
          themeVariable: '--ion-tabbar-md-text-color-active',
          value: '#fa0fa0',
        },
        { themeVariable: '--ion-background-color', value: '#ffffff' },

        { themeVariable: '--ion-color-secondary', value: '#7ceda9' },
        { themeVariable: '--ion-color-secondary-rgb', value: '80,200,255' },
        { themeVariable: '--ion-color-secondary-contrast', value: '#ffffff' },
        {
          themeVariable: '--ion-color-secondary-contrast-rgb',
          value: '255,255,255',
        },
        { themeVariable: '--ion-color-secondary-shade', value: '#46b0e0' },
        { themeVariable: '--ion-color-secondary-tint', value: '#62ceff' },

        { themeVariable: '--ion-color-tertiary', value: '#fdfdfd' },
        { themeVariable: '--ion-color-tertiary-rgb', value: '106,100,255' },
        { themeVariable: '--ion-color-tertiary-contrast', value: '#ffffff' },
        {
          themeVariable: '--ion-color-tertiary-contrast-rgb',
          value: '255,255,255',
        },
        { themeVariable: '--ion-color-tertiary-shade', value: '#5d58e0' },
        { themeVariable: '--ion-color-tertiary-tint', value: '#7974ff' },

        { themeVariable: '--ion-color-success', value: '#2fdf75' },
        { themeVariable: '--ion-color-success-rgb', value: '47,223,117' },
        { themeVariable: '--ion-color-success-contrast', value: '#000000' },
        { themeVariable: '--ion-color-success-contrast-rgb', value: '0,0,0' },
        { themeVariable: '--ion-color-success-shade', value: '#29c467' },
        { themeVariable: '--ion-color-success-tint', value: '#44e283' },

        { themeVariable: '--ion-color-warning', value: '#ffd534' },
        { themeVariable: '--ion-color-warning-rgb', value: '55,213,52' },
        { themeVariable: '--ion-color-warning-contrast', value: '#000000' },
        { themeVariable: '--ion-color-warning-contrast-rgb', value: '0,0,0' },
        { themeVariable: '--ion-color-warning-shade', value: '#e0bb2e' },
        { themeVariable: '--ion-color-warning-tint', value: '#ffd948' },

        { themeVariable: '--ion-color-danger', value: '#ff4961' },
        { themeVariable: '--ion-color-danger-rgb', value: '255,73,97' },
        { themeVariable: '--ion-color-danger-contrast', value: '#ffffff' },
        {
          themeVariable: '--ion-color-danger-contrast-rgb',
          value: '255,255,255',
        },
        { themeVariable: '--ion-color-danger-shade', value: '#e04055' },
        { themeVariable: '--ion-color-danger-tint', value: '#ff5b71' },

        { themeVariable: '--ion-color-dark', value: '#333333' },
        { themeVariable: '--ion-color-dark-rgb', value: '244,245,248' },
        { themeVariable: '--ion-color-dark-contrast', value: '#000000' },
        { themeVariable: '--ion-color-dark-contrast-rgb', value: '0,0,0' },
        { themeVariable: '--ion-color-dark-shade', value: '#d7d8da' },
        { themeVariable: '--ion-color-dark-tint', value: '#f5f6f9' },

        { themeVariable: '--ion-color-medium', value: '#99b2bf' },
        { themeVariable: '--ion-color-medium-rgb:', value: '152,154,162' },
        { themeVariable: '--ion-color-medium-contrast', value: '#000000' },
        { themeVariable: '--ion-color-medium-contrast-rgb', value: '0,0,0' },
        { themeVariable: '--ion-color-medium-shade', value: '#86888f' },
        { themeVariable: '--ion-color-medium-tint', value: '#a2a4ab' },

        { themeVariable: '--ion-color-light', value: '#eef2f4' },
        { themeVariable: '--ion-color-light-rgb', value: '34,36,40' },
        { themeVariable: '--ion-color-light-contrast', value: '#ffffff' },
        {
          themeVariable: '--ion-color-light-contrast-rgb',
          value: '255,255,255',
        },
        { themeVariable: '--ion-color-light-shade', value: '#1e2023' },
        { themeVariable: '--ion-color-light-tint', value: '#383a3e' },

        { themeVariable: '--ion-primary-text', value: 'rgb(83 100 113)' },
        { themeVariable: '--ion-chat-primary-text', value: '#ffffff' },
        {
          themeVariable: '--ion-logo-color',
          value: 'var(--ion-color-dark)',
        },

        { themeVariable: '--ion-border-color', value: 'var(--ion-color-light' },
        {
          themeVariable: '--cropper-outline-color ',
          value: 'rgb(207, 17, 17)',
        },
        { themeVariable: '--cropper-overlay-color', value: 'yellow' },

        {
          themeVariable: '--desktop-navbar-background',
          value: 'var(--ion-color-primary-shade)',
        },
        {
          themeVariable: '--desktop-navbar-icon-color',
          value: 'var(--ion-color-dark)',
        },
        {
          themeVariable: '--desktop-navbar-notes-badge-color',
          value: 'var(--ion-color-warning)',
        },
        {
          themeVariable: '--desktop-navbar-title',
          value: 'var(--ion-color-dark)',
        },
      ],
    },
    {
      name: 'day',
      previewColor: 'rgb(29 155 240)',
      styles: [
        { themeVariable: '--ion-color-primary', value: 'rgb(29 155 240)' },
        { themeVariable: '--ion-color-primary-rgb', value: '66,140,255' },
        { themeVariable: '--ion-color-primary-contrast', value: '#fdfdfd' },
        {
          themeVariable: '--ion-color-primary-contrast-rgb',
          value: '255,255,255',
        },
        {
          themeVariable: '--ion-color-primary-shade',
          value: 'rgb(247 249 249)',
        },
        { themeVariable: '--ion-color-primary-tint', value: '#5598ff' },

        { themeVariable: '--ion-item-ios-background-color', value: '#ffffff' },
        { themeVariable: '--ion-item-md-background-color', value: '#ffffff' },
        { themeVariable: '--ion-tabbar-background-color', value: '#ffffff' },
        {
          themeVariable: '--ion-tabbar-ios-text-color-active',
          value: '#fa0fa0',
        },
        {
          themeVariable: '--ion-tabbar-md-text-color-active',
          value: '#fa0fa0',
        },
        { themeVariable: '--ion-background-color', value: '#ffffff' },

        { themeVariable: '--ion-color-secondary', value: '#50c8ff' },
        { themeVariable: '--ion-color-secondary-rgb', value: '80,200,255' },
        { themeVariable: '--ion-color-secondary-contrast', value: '#ffffff' },
        {
          themeVariable: '--ion-color-secondary-contrast-rgb',
          value: '255,255,255',
        },
        { themeVariable: '--ion-color-secondary-shade', value: '#46b0e0' },
        { themeVariable: '--ion-color-secondary-tint', value: '#62ceff' },

        { themeVariable: '--ion-color-tertiary', value: '#6a64ff' },
        { themeVariable: '--ion-color-tertiary-rgb', value: '106,100,255' },
        { themeVariable: '--ion-color-tertiary-contrast', value: '#ffffff' },
        {
          themeVariable: '--ion-color-tertiary-contrast-rgb',
          value: '255,255,255',
        },
        { themeVariable: '--ion-color-tertiary-shade', value: '#5d58e0' },
        { themeVariable: '--ion-color-tertiary-tint', value: '#7974ff' },

        { themeVariable: '--ion-color-success', value: '#2fdf75' },
        { themeVariable: '--ion-color-success-rgb', value: '47,223,117' },
        { themeVariable: '--ion-color-success-contrast', value: '#000000' },
        { themeVariable: '--ion-color-success-contrast-rgb', value: '0,0,0' },
        { themeVariable: '--ion-color-success-shade', value: '#29c467' },
        { themeVariable: '--ion-color-success-tint', value: '#44e283' },

        { themeVariable: '--ion-color-warning', value: '#ffd534' },
        { themeVariable: '--ion-color-warning-rgb', value: '55,213,52' },
        { themeVariable: '--ion-color-warning-contrast', value: '#000000' },
        { themeVariable: '--ion-color-warning-contrast-rgb', value: '0,0,0' },
        { themeVariable: '--ion-color-warning-shade', value: '#e0bb2e' },
        { themeVariable: '--ion-color-warning-tint', value: '#ffd948' },

        { themeVariable: '--ion-color-danger', value: '#ff4961' },
        { themeVariable: '--ion-color-danger-rgb', value: '255,73,97' },
        { themeVariable: '--ion-color-danger-contrast', value: '#ffffff' },
        {
          themeVariable: '--ion-color-danger-contrast-rgb',
          value: '255,255,255',
        },
        { themeVariable: '--ion-color-danger-shade', value: '#e04055' },
        { themeVariable: '--ion-color-danger-tint', value: '#ff5b71' },

        { themeVariable: '--ion-color-dark', value: '#15202b' },
        { themeVariable: '--ion-color-dark-rgb', value: '244,245,248' },
        { themeVariable: '--ion-color-dark-contrast', value: '#000000' },
        { themeVariable: '--ion-color-dark-contrast-rgb', value: '0,0,0' },
        { themeVariable: '--ion-color-dark-shade', value: '#d7d8da' },
        { themeVariable: '--ion-color-dark-tint', value: '#f5f6f9' },

        { themeVariable: '--ion-color-medium', value: '#989aa2' },
        { themeVariable: '--ion-color-medium-rgb:', value: '152,154,162' },
        { themeVariable: '--ion-color-medium-contrast', value: '#000000' },
        { themeVariable: '--ion-color-medium-contrast-rgb', value: '0,0,0' },
        { themeVariable: '--ion-color-medium-shade', value: '#86888f' },
        { themeVariable: '--ion-color-medium-tint', value: '#a2a4ab' },

        { themeVariable: '--ion-color-light', value: '#dfe5e8' },
        { themeVariable: '--ion-color-light-rgb', value: '34,36,40' },
        { themeVariable: '--ion-color-light-contrast', value: '#ffffff' },
        {
          themeVariable: '--ion-color-light-contrast-rgb',
          value: '255,255,255',
        },
        { themeVariable: '--ion-color-light-shade', value: '#1e2023' },
        { themeVariable: '--ion-color-light-tint', value: '#383a3e' },

        { themeVariable: '--ion-primary-text', value: 'rgb(83 100 113)' },
        { themeVariable: '--ion-chat-primary-text', value: '#ffffff' },
        { themeVariable: '--ion-logo-color', value: 'rgb(29 155 240)' },

        { themeVariable: '--ion-border-color', value: '#837c7c' },
        {
          themeVariable: '--cropper-outline-color ',
          value: 'rgb(207, 17, 17)',
        },
        { themeVariable: '--cropper-overlay-color', value: 'yellow' },
      ],
    },
    {
      name: 'night',
      previewColor: '#15202b',
      styles: [
        { themeVariable: '--ion-color-primary', value: 'rgb(29 155 240)' },
        { themeVariable: '--ion-color-primary-rgb', value: '66,140,255' },
        { themeVariable: '--ion-color-primary-contrast', value: '#fdfdfd' },
        {
          themeVariable: '--ion-color-primary-contrast-rgb',
          value: '255,255,255',
        },
        { themeVariable: '--ion-color-primary-shade', value: '#192734' },
        { themeVariable: '--ion-color-primary-tint', value: '#5598ff' },

        { themeVariable: '--ion-item-ios-background-color', value: '#15202b' },
        { themeVariable: '--ion-item-md-background-color', value: '#15202b' },
        { themeVariable: '--ion-tabbar-background-color', value: '#15202b' },
        {
          themeVariable: '--ion-tabbar-ios-text-color-active',
          value: '#ffffff',
        },
        {
          themeVariable: '--ion-tabbar-md-text-color-active',
          value: '#ffffff',
        },
        { themeVariable: '--ion-background-color', value: '#15202b' },

        { themeVariable: '--ion-color-secondary', value: '#50c8ff' },
        { themeVariable: '--ion-color-secondary-rgb', value: '80,200,255' },
        { themeVariable: '--ion-color-secondary-contrast', value: '#ffffff' },
        {
          themeVariable: '--ion-color-secondary-contrast-rgb',
          value: '255,255,255',
        },
        { themeVariable: '--ion-color-secondary-shade', value: '#46b0e0' },
        { themeVariable: '--ion-color-secondary-tint', value: '#62ceff' },

        { themeVariable: '--ion-color-tertiary', value: '#6a64ff' },
        { themeVariable: '--ion-color-tertiary-rgb', value: '106,100,255' },
        { themeVariable: '--ion-color-tertiary-contrast', value: '#ffffff' },
        {
          themeVariable: '--ion-color-tertiary-contrast-rgb',
          value: '255,255,255',
        },
        { themeVariable: '--ion-color-tertiary-shade', value: '#5d58e0' },
        { themeVariable: '--ion-color-tertiary-tint', value: '#7974ff' },

        { themeVariable: '--ion-color-success', value: '#2fdf75' },
        { themeVariable: '--ion-color-success-rgb', value: '47,223,117' },
        { themeVariable: '--ion-color-success-contrast', value: '#000000' },
        { themeVariable: '--ion-color-success-contrast-rgb', value: '0,0,0' },
        { themeVariable: '--ion-color-success-shade', value: '#29c467' },
        { themeVariable: '--ion-color-success-tint', value: '#44e283' },

        { themeVariable: '--ion-color-warning', value: '#ffd534' },
        { themeVariable: '--ion-color-warning-rgb', value: '55,213,52' },
        { themeVariable: '--ion-color-warning-contrast', value: '#000000' },
        { themeVariable: '--ion-color-warning-contrast-rgb', value: '0,0,0' },
        { themeVariable: '--ion-color-warning-shade', value: '#e0bb2e' },
        { themeVariable: '--ion-color-warning-tint', value: '#ffd948' },

        { themeVariable: '--ion-color-danger', value: '#ff4961' },
        { themeVariable: '--ion-color-danger-rgb', value: '255,73,97' },
        { themeVariable: '--ion-color-danger-contrast', value: '#ffffff' },
        {
          themeVariable: '--ion-color-danger-contrast-rgb',
          value: '255,255,255',
        },
        { themeVariable: '--ion-color-danger-shade', value: '#e04055' },
        { themeVariable: '--ion-color-danger-tint', value: '#ff5b71' },

        { themeVariable: '--ion-color-dark', value: '#f4f5f8' },
        { themeVariable: '--ion-color-dark-rgb', value: '244,245,248' },
        { themeVariable: '--ion-color-dark-contrast', value: '#000000' },
        { themeVariable: '--ion-color-dark-contrast-rgb', value: '0,0,0' },
        { themeVariable: '--ion-color-dark-shade', value: '#d7d8da' },
        { themeVariable: '--ion-color-dark-tint', value: '#f5f6f9' },

        { themeVariable: '--ion-color-medium', value: '#989aa2' },
        { themeVariable: '--ion-color-medium-rgb:', value: '152,154,162' },
        { themeVariable: '--ion-color-medium-contrast', value: '#000000' },
        { themeVariable: '--ion-color-medium-contrast-rgb', value: '0,0,0' },
        { themeVariable: '--ion-color-medium-shade', value: '#86888f' },
        { themeVariable: '--ion-color-medium-tint', value: '#a2a4ab' },

        { themeVariable: '--ion-color-light', value: 'rgb(52 64 73)' },
        { themeVariable: '--ion-color-light-rgb', value: '34,36,40' },
        { themeVariable: '--ion-color-light-contrast', value: '#ffffff' },
        {
          themeVariable: '--ion-color-light-contrast-rgb',
          value: '255,255,255',
        },
        { themeVariable: '--ion-color-light-shade', value: '#1e2023' },
        { themeVariable: '--ion-color-light-tint', value: '#383a3e' },

        { themeVariable: '--ion-primary-text', value: '#fdfdfd' },
        { themeVariable: '--ion-chat-primary-text', value: '#ffffff' },

        {
          themeVariable: '--ion-border-color',
          value: 'var(--ion-color-light)',
        },
        {
          themeVariable: '--cropper-outline-color ',
          value: 'rgb(207, 17, 17)',
        },
        { themeVariable: '--cropper-overlay-color', value: 'yellow' },

        {
          themeVariable: '--ion-logo-color',
          value: 'var(--ion-color-primary-contrast)',
        },
        {
          themeVariable: '--desktop-navbar-background',
          value: 'var(--ion-color-primary)',
        },
        {
          themeVariable: '--desktop-navbar-icon-color',
          value: 'var(--ion-color-primary-contrast)',
        },
        {
          themeVariable: '--desktop-navbar-notes-badge-color',
          value: 'var(--ion-color-warning)',
        },
        {
          themeVariable: '--desktop-navbar-title',
          value: 'var(--ion-color-primary-contrast)',
        },
      ],
    },
  ];

  constructor() {}

  roleRouting(role: UserType) {
    if (role === UserType.serviceProvider) {
      return this.serviceProvider + '-dashboard';
    } else if (role === UserType.serviceConsumer) {
      return this.serviceConsumer + '-dashboard';
    } else if (role === UserType.admin) {
      return '/admin-dasboard';
    } else if (role === UserType.guest || role === undefined) {
      return '/onboarding';
    }
  }

  roleAsText(role: UserType) {
    let roleText = '';
    switch (role) {
      case 0:
        roleText = this.serviceProvider;
        break;
      case 1:
        roleText = this.serviceConsumer;
        break;
      case 2:
        roleText = 'Admin';
        break;
      default:
        roleText = 'guest';
        break;
    }
    return roleText;
  }
}

enum UserType {
  serviceProvider,
  serviceConsumer,
  guest,
  admin,
}
