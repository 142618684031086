import { Component, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { ViewService } from 'src/app/services/view/view.service';

@Component({
  selector: 'app-vendor-home',
  templateUrl: './vendor-home.page.html',
  styleUrls: ['./vendor-home.page.scss'],
})
export class VendorHomePage implements OnInit {

  constructor(
    private viewService: ViewService,
    private menuCtrl: MenuController
   ) { }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.viewService.setPageType('front-end');
    this.menuCtrl.enable(false);
  }

}
