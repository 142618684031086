/* eslint-disable @typescript-eslint/dot-notation */
import { Component, ElementRef, EventEmitter, Input, OnInit, Output,
   QueryList, OnChanges, SimpleChanges, ViewChildren } from '@angular/core';
import { TicketsService } from 'src/app/apps/theluckiestafrica/services/tickets.service';

@Component({
  selector: 'app-input-mask',
  templateUrl: './input-mask.component.html',
  styleUrls: ['./input-mask.component.scss'],
})
export class InputMaskComponent implements OnInit, OnChanges {
  @ViewChildren('digitInputs') digitInputs!: QueryList<ElementRef>;

  @ViewChildren('formRow') rows: any;
  @Input() public buttonLabel;
  @Input() public otpLength;
  @Input() public formTitle;
  @Input() public otpPrefix;
  @Output() inputValue: EventEmitter<any> = new EventEmitter();
  @Input() public load;
  @Input() inputStyle;
  @Input() inputType = 'text';
  @Input()  value;
  @Input() constantEmit;
  @Input() key;
  digits: string[] = [];


  formValid = false;

  constructor(private ticketService: TicketsService) { }

  ngOnInit(): void {
   this.digits =  Array(this.otpLength).fill(undefined);
   this.populateForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && this.value) {
     this.populateForm();
    }
  }

  populateForm(){
    this.digits = this.value && this.value.split('').slice(0, 5) || [];

    const definedDigits = this.digits.filter((digit) => digit !== undefined);
    //console.log(definedDigits);
    if(definedDigits.length > 0 && definedDigits.length < this.otpLength){
      //console.log(definedDigits.length);
      //this.digitInputs.toArray()[definedDigits.length + 1].nativeElement.focus();
    }

    if(this.digits.length <= this.otpLength){
      for(let i = this.digits.length; i < this.otpLength; i++){
        this.digits.push(undefined);
      }
    }
  }

  onDigitChange(newValue: string, index: number) {
    const value = [];
    if (newValue && index < this.digits.length - 1) {
      this.digitInputs.toArray()[index + 1].nativeElement.focus();
    }else{
      this.validateAndInitAction();
    }
   // if(this.constantEmit){
    this.digitInputs.toArray().forEach((input) => {
      value.push(input.nativeElement.value);
    });
      const finalVal = value.filter((val) => val !== '');
      this.inputValue.emit({data:finalVal.join('')});
      if(finalVal.length === this.digits.length){
        if(this.key === 'ticket'){
          this.ticketService.ticketData.ticketValue = finalVal.join('');
          this.ticketService.ticketData.ticketNumber = finalVal.join('');
        }
      };
   // }
    this.formValid = this.digitInputs.toArray().every((input) => input.nativeElement.value);
  }

  onKeyDown(event: KeyboardEvent, index: number) {
    if (event.key === 'Backspace' && index > 0) {
      const previousInput = this.digitInputs.toArray()[index - 1].nativeElement;
      previousInput.focus();
      previousInput.select();
      const value = [];
      if(this.constantEmit){
        this.digitInputs.toArray().forEach((input) => {
          value.push(input.nativeElement.value);
        });
          const finalVal = value.filter((val) => val !== '');
          this.inputValue.emit({data:finalVal.join('')});
        }
    }
  }

  onFocus(index: number) {
    // if (this.digits[index] && index > 0) {
    //   this.digitInputs.toArray()[index - 1].nativeElement.focus();
    // }
  }

  validateAndInitAction() {
    const value = [];
    this.digitInputs.toArray().forEach((input) => {
      value.push(input.nativeElement.value);
    });
    const finalVal = value.filter((val) => val !== '');
    if(finalVal.length === this.otpLength){
      this.inputValue.emit({data:finalVal.join('')});
    }
  }

}
