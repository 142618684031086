

import { RouteService } from 'src/app/services/route/route.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController, NavParams, ToastController } from '@ionic/angular';
import { Validators, FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { PaymentService } from '../../services/payment.service';
import {Observable, Subscription, timer, interval} from 'rxjs';
import { CrudService } from 'src/app/services/crud/crud.service';
import { LuckiestAuthService } from '../../services/luckiest-auth.service';
import { LocationService } from 'src/app/services/location/location.service';
import PaystackPop from '@paystack/inline-js';
import { TicketsService } from '../../services/tickets.service';
import { ViewService } from 'src/app/services/view/view.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-init-pay',
  templateUrl: './init-pay.component.html',
  styleUrls: ['./init-pay.component.scss'],
})
export class InitPayComponent implements OnInit {


  timerSubscription: Subscription;

  data: any;
  viewMode: any;

  amountToPay: any;
  selectedPaymethod: any;
  processingSth: any = false;
  payReturnUrl: any;
  showPayFrame: any = true;
  transactionId: any;
  transactionRef: any;
  reference = '';

  failedRequests: any = [];

  initCheckOutResult: any;

  listenToPaymentStatus: boolean;
  checkOutData: any;
  geoData: any;
  constructor(
    private modalCtrl: ModalController,
    public paymentService: PaymentService,
    private toastCtrl: ToastController,
    private sanitizer: DomSanitizer,
    public router: RouteService,
    private crudService: CrudService,
    private luckAuth: LuckiestAuthService,
    private locService: LocationService,
    private ticketService: TicketsService,
    private viewService: ViewService
  ) {

  }



  ngOnInit() {
   setTimeout(() => {
   this.initiateCheckout();
   }, 2000);

   //  this.transactionId = 1083875333;

   if(this.listenToPaymentStatus === true){
   this.timerSubscription = interval(5000)
   .subscribe((val) => {
     this.checkPaymentStatus();
    });
    }

   }

   async toast(message,type){
      const toast = await this.toastCtrl.create({
       message,
       color:type,
       duration:5000,
       position:'top'
      });

      toast.present();
   }


   transformUrl(url) {
     return this.sanitizer.bypassSecurityTrustResourceUrl(url);
   }

   closeModal(){
     this.modalCtrl.dismiss(this.transactionRef);
   }

   reloadPage(){
     setTimeout(() => {
     // this.makePayment(this.authService.sessionVars.hash);
     }, 3000);
   }

   addToFailedRequests(item){
     if(this.failedRequests.indexOf(item) === -1) {
       this.failedRequests.push(item);
     }
   }

   removeFromFailedRequests(item){
     const index = this.failedRequests.indexOf(item);
    if (index !== -1) {
        this.failedRequests.splice(index, 1);
     }
   }

   paymentInit() {
    console.log('Payment initialized');
  }

   paymentDone(ref: any) {
   // this.title = 'Payment successfull';
    console.log('pay ref', ref);
  }

  paymentCancel() {
    console.log('payment failed');
  }


  initiateCheckout(){
   this.payReturnUrl = this.payReturnUrl;
   this.locService.getGeoLocation().then((res) => {
    if(res){
      const countryCode = res.country_code2;
      this.makePaymentCall(countryCode,res);
    }else{
      this.crudService.localGet('selectedCountry').then((ress: any) => {
        console.log(ress);
        if(ress !== null && ress !== undefined) {
          const countryCode = ress.country_code2;
          this.makePaymentCall(countryCode,ress);
         }
       });
      }
    });
  }

  makePaymentCall(countryCode,countryData){
    try {
      this.paymentService.initiateCheckout(countryCode,countryData).then((res: any)=>{
       console.log(res);
       res.subscribe((result)=>{
        this.initCheckOutResult = result;
        if(result && result.data !== null && result.status !== false ){
        if(result && result.data && result.data.payMethod === 'paystack'){
          this.initiatePaystackCheckout();
        }else{
       this.payReturnUrl = this.transformUrl(result.data.checkoutUrl);
        this.listenToPaymentStatus = true;
       // console.log(result);
          }
        }else{
        this.viewService.notify('danger','This Transaction has Expired Please try Again',5000);
        }
       });
      });
    } catch (error) {
      console.log(error);
    }
  }

  checkPaymentStatus(){
    this.paymentService.checkPaymentStatus(this.transactionId).subscribe((result: any)=>{
      console.log(result);
    });
  }

  confirmPayment(){
    const payload = {
      responseCode: 'string',
      status: 'string',
      data: {
        checkoutId: 'string',
        salesInvoiceId: 'string',
        clientReference: 'string',
        status: 'string',
        amount: 0,
        customerPhoneNumber: 'string',
        paymentDetails: {
          mobileMoneyNumber: 'string',
          paymentType: 'string'
        },
        description: 'string'
      }
    };
     this.paymentService.paymentCallBack(payload).subscribe((result: any)=>{
      console.log(result);
     });
  }


  dismissPayment(){
    this.modalCtrl.dismiss(true);
  }

  initiatePaystackCheckout() {
    const self = this;
    const paystack = new PaystackPop();
    return  new Promise((resolve)=>{
      paystack.newTransaction({
      key: this.paymentService.paystasckPublicKey,
      email: this.luckAuth.sessionVars.email,
      amount: this.ticketService.ticketData.ticketAmount * 100,

  onSuccess: (transaction) => {
    this.processPayStackTransaction(transaction);
    resolve(transaction);
    this.transactionRef = transaction.data.reference;
  },
  onCancel: () => {
    this.modalCtrl.dismiss(this.transactionRef);
   },
   onClose: () => {
    this.modalCtrl.dismiss(this.transactionRef);
   },
    onError: (error) => {
      console.log(error);
    }
   });
  });
   }

   processPayStackTransaction(returnData){

    if(!this.paymentService.paystackTrackingToken){
      this.crudService.localGet('payStackToken').then(result=>{
      //  console.log(result);
        Object.assign(returnData,{trackingToken:result.value});
        //console.log(returnData);
        this.confirmPaystackPurchase(returnData);
      });
    }else{
      Object.assign(returnData,{trackingToken:this.paymentService.paystackTrackingToken});
      //console.log(returnData);
      this.confirmPaystackPurchase(returnData);
    }
   }

  confirmPaystackPurchase(returnData){
     if(returnData.status !== 'success'){
      this.viewService.notify('danger','Transaction Failed. Please check Details and Try again',5000);
     }
     console.log(returnData);
     try {
      this.paymentService.updatePayStackTransactionStatus(returnData).subscribe((result: any)=>{
         console.log(result);
        if(result && result.status === true){
          this.viewService.notify('success','Transaction Successful, Redirecting You',5000);
         this.viewService.goTo(`${'paystack-checkout-success/'+returnData.trackingToken}`);
        }
      },error=>{
       console.log(error);
        this.viewService.notify('danger','Transaction Token Expired. Please try again',5000);
      });
     } catch (error) {
     // console.log(error);
     }

   }

}


