/* eslint-disable @typescript-eslint/member-ordering */
import { CrudService } from 'src/app/services/crud/crud.service';
import { PaymentService } from './../../apps/theluckiestafrica/services/payment.service';
import { LuckiestAuthService } from './../../apps/theluckiestafrica/services/luckiest-auth.service';
import { TicketGeneratorComponent } from './../../apps/theluckiestafrica/components/ticket-generator/ticket-generator.component';
import { DomSanitizer } from '@angular/platform-browser';
import { MenuController, IonSlides, ModalController, AlertController, LoadingController, IonContent } from '@ionic/angular';
import { Component, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ViewService } from 'src/app/services/view/view.service';
import { environment } from 'src/environments/environment.prod';
import { interval, Subscription, } from 'rxjs';
import { createPopup, createWidget } from '@typeform/embed';
import { WaitlistFormComponent } from 'src/app/components/waitlist-form/waitlist-form.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnInit,OnDestroy {
  @ViewChild('scrollArea', { static: false }) content: IonContent;
  jobs: any = [];
  products: any = [];
  moreItems: any = [];
  moreItemsOffset: any = 0;
  moreItemsLimit: any = 12;
  endPropertyLoading: any = false;
  itemsFallback: any;
  adList: any = [];
  adData: any = {};
  expectingApiResponse = true;
  doingReload = false;
  requestFailedMessage: any;
  networkError = false;
  openViewModal = false;
  paystackSuccessToken = null;
 links = [];
 recentTickets = [];
 headerStyles = {
  'background-color': 'transparent',
};

videoPlaying = false;


slides = [
  {img:'../../../assets/luckiest-africa/new-assets/web-pix-13-min.jpg' ,cssClass:''},
  {img:'../../../assets/luckiest-africa/new-assets/web-pix-15-min.jpg' ,cssClass:''},
  {img:'../../../assets/luckiest-africa/new-assets/web-pix-16-min.jpg' ,cssClass:''},
];


  slideOpts = {
    initialSlide: 0,
    speed: 400,
    autoplay: {
      delay: 2000,
    },
  };

 fortuneWheelTimeout = 1000;
 showFortuneWheel = true;
 sanitizedUrl: any = '';

 playerSteps: any [] = [
  {
    title:'Register',
    description:'Click on Register to setup your account',
    active:true,
    color: 'blue-gradient',
    icon:'document-lock-outline'
    },
  {
    title:'Play to Win',
    description:'Enter your preferred ticket number or generate a random ticket number in a community',
    active:false,
    color:'purple-gradient',
    icon:'play-outline'
  },
  {title:'Buy Your ticket',
  description:'Buy any amount of ticket in a community for yourself or someone',
  active:false,
  color:'olive-gradient',
  icon:'ticket-outline'
},
  {title:'Make Payment',
  description:'Securely pay using your mobile money(available on all networks), wallet or bank card',
  active:false,
  color:'green-gradient',
  icon:'cash-outline'
},
];

faqData = [
  {
    title: 'What is The Luckiest Africa?',
    content: 'The Luckiest is a seasonal lottery ' +
             ' (first ever in Ghana) in partnership with NLA Caritas ' +
             ' Lottery to offer players the chance to win various ' +
             ' life-changing cash prizes. ' +
             ' For the first time in Ghana, over 10,000 people can win ' +
             ' at a time, to live their dreams and change their ' +
             ' community.'
  },
  {
    title: 'Is the Luckiest Africa licensed by the regulator?',
    // eslint-disable-next-line max-len
    content: 'Yes The Luckiest Africa is duly licensed by the National Lottery Authority https://nlacaritas.com under the Caritas platform.'
  },
  {
    title: 'What is the least amount I can participate with?',
    content: 'You can play with as low as Ghc1 ' +
             ' in a community by purchasing a ticket with that ' +
             ' amount.'
  },
  {
    title: 'What is the highest amount I can participate with?',
    content: 'You can increase your chances of ' +
             ' winning by playing with as high as Ghc5,000 in a ' +
             ' community through ticket purchases.'
  },
  {
    title: 'What is a community?',
    content: 'A community is the name given ' +
             ' to a group with similar aspirations, hope and a ' +
             ' common way of doing things. A Community captures ' +
             ' every facet of society. ' +
             ' In The Luckiest, a community is allocated 1000 tickets ' +
             ' for anyone to join and purchase. This is to recognise ' +
             ' that there is LUCK in everything we do. ' +
             ' Participants can buy across communities they believe ' +
             ' has more luck.'
  },
  {
    title: 'Can I join any community, and how many can I join?',
    content: 'Participants can join any ' +
             ' community they choose to. There is no limit to which ' +
             ' community to buy a ticket from. Luck is everywhere!'
  },
  {
    title: 'What do I need to be able to participate?',
    content: 'To play The Luckiest, you need to ' +
             ' be 18 years and above. ' +
             ' You can play by either dialling *712# and completing ' +
             ' the ticket purchase with your mobile money account ' +
             ' (available on all networks) or by logging on to our web ' +
             ' portal via https://theluckiestafrica.com/'
  },
  // {
  //   title: 'What is the minimum age to participate?',
  //   content: 'All players are to be 18 years and ' +
  //            ' above. Minors are not eligible to participate in The ' +
  //            ' Luckiest.'
  // },
  // {
  //   title: 'How many tickets can a single person buy?',
  //   content: 'Ticket purchase is unlimited, and ' +
  //            ' a person can buy as many as they feel Lucky to buy. ' +
  //            ' Participate responsibly.'
  // },
  // {
  //   title: 'Can I buy a ticket for someone?',
  //   content: 'Yes. You can buy a ticket for ' +
  //            ' another person using our USSD code *712# or web ' +
  //            ' portal https://theluckiestafrica.com as well as from a ' +
  //            ' vendor.'
  // },
  // {
  //   title: 'Can I pay with any mobile money number?',
  //   content: 'Payment can only be made with a ' +
  //            ' mobile money number assigned to the buyer. Always ' +
  //            ' ensure to have enough funds for the required ticket ' +
  //            ' purchase amount. ' +
  //            ' Mobile money payment is available on all networks.'
  // },
  // {
  //   title: 'How do I claim my winning price?',
  //   content: 'Winning amount payable via ' +
  //            ' mobile money per Bank of Ghana guidelines would be ' +
  //            ' made instantly after the draw. ' +
  //            ' Huge sums of winning money can be claimed ' +
  //            ' through cheque upon verification from the Luckiest ' +
  //            ' Head office in Accra. ' +
  //            ' For participants that are in the diaspora, your ' +
  //            ' winnings will be put on a prepaid debit card.'
  // },
  // {
  //   title: 'How do I know I have won?',
  //   content: 'All winners will be notified via ' +
  //            ' text message on the phone number submitted when ' +
  //            ' registering, emails provided or phone call after the live ' +
  //            ' draw, which will be telecast live on national television ' +
  //            ' on the 23rd December..'
  // },
  // {
  //   title: 'Can a winner remain anonymous?',
  //   content: 'Winners can remain anonymous per ' +
  //            ' the agreed terms and conditions, and also the amount won. ' +
  //            ' Refer to Ts&Cs here'
  // },
  // {
  //   title: 'What does one win after participating?',
  //   content: 'All winning numbers, after ' +
  //            ' verification, would be awarded the respective cash ' +
  //            ' amount per ticket value purchased.'
  // },
  // {
  //   title: 'How long does it take for the draw to happen?',
  //   content: 'The draw happens on the last ' +
  //            ' Saturday before Christmas.'
  // }
];


showCountDown =  false;
accessModalTriggerCount = 0;

geniusPass = 'genius123';
showAccessModal = false;

public alertButtons = [{
  name: 'confirm',
  cssClass: 'dark',
  text: 'Confirm',
  handler: (alertData) => {
   if(alertData.accessCode === this.geniusPass){
    this.showCountDown = false;
    this.crudService.localUpdate('accessCode',{value:alertData.accessCode});
    //this.viewService.loadPageForTicket(5);
   }
  }
}];
  public alertInputs: any[] = [
    {
      name:'accessCode',
      value:null,
      placeholder: 'Pass',
      attributes: {
        maxlength: 9,
      },
    },
  ];

subscription: Subscription;
currentIndex = 0;
  constructor(
    public viewService: ViewService,
    public router: Router,
    private menuCtrl: MenuController,
    private sanitize: DomSanitizer,
    private modaCtrl: ModalController,
    private alertCtrl: AlertController,
    private loadCtrl: LoadingController,
    public luckAuth: LuckiestAuthService,
    private activeRoute: ActivatedRoute,
    private paymentService: PaymentService,
    private crudService: CrudService,
  ) {
    this.sanitizedUrl = this.sanitizeUrl('https://youtu.be/S9inroAiZyk');
    this.paystackSuccessToken = this.activeRoute.snapshot.paramMap.getAll('paystack_checkout_id')[0];
   // console.log(this.paystackSuccessToken);
  }

  hideCountDown(){}
  ngOnInit() {

    this.crudService.localGet('accessCode').then(res=>{
      if(res){
        if(res.value === this.geniusPass){
          this.showCountDown = false;
        }
      }
    });
    // this.crudService.localDelete('accessCode');
   // this.viewService.initPayment();
   // this.loadPage(4);
    const source = interval(5000);
    const text = 'Your Text Here';
    this.subscription = source.subscribe(val =>{
     // let currentIndex = this.playerSteps.indexOf(this.playerSteps.filter(i=>i.active === true)[0]);

     if(this.currentIndex < this.playerSteps.length -1)
      {
        this.playerSteps[this.playerSteps.length -1].active = false;
        this.currentIndex += 1;
        this.playerSteps[this.currentIndex].active = true;
        if(this.currentIndex > 0){
          this.playerSteps[this.currentIndex -1].active = false;
        }

      }else if(this.currentIndex === this.playerSteps.length - 1){
        this.currentIndex = 0;
        this.playerSteps[0].active = true;
      }

      // console.log(this.currentIndex);
      // console.log(this.playerSteps.length -1);

    });




    setTimeout(() => {
      this.showFortuneWheel = false;
    }, this.fortuneWheelTimeout);
    //this.jobs = this.jobService.jobs;
    this.viewService.setViewTitle('Home Page');
    this.links = [
      {title: 'Sellers', url: '/seller-list', icon: 'people-circle-outline',type:'link'},
      {title: 'Sell on '+environment.app.name, url: '/call-to-sell', icon: 'people-outline',type:'modal'},
      {title: 'Customer Support', url: '/support', icon: 'headset',type:'modal'},
      {title: 'Terms Of Service', url: '/article/terms-of-service', icon: 'share-social-outline',type:'link'},
    ];

    this.activeRoute.queryParams.subscribe(params => {
      if(params){
      if(params.hasOwnProperty('transaction')){
        if(params.transaction === 'success'){
          //this.viewService.notify('success','Transaction Successful',5000);

          this.crudService.localGet('recentTickets').then(rec=>{
            if(rec){
             this.recentTickets  = rec;
            }

          if(params.hasOwnProperty('checkoutid')){
          const checkoutId = params.checkoutid;
           if(this.recentTickets.indexOf(checkoutId) < 0){
           this.paymentService.checkPaymentStatus(checkoutId).subscribe((res: any)=>{

           if(res.status === true && res.data.paymentResponseCode === 'Success'){
             this.viewService.notify('success','Transaction Successful',5000);
             this.recentTickets.push(checkoutId);
              this.crudService.localUpdate('recentTickets',this.recentTickets);
              this.viewService.loadPageForTicket(5);
             }
           });
          }
          }
        });
       }
      }else{
       // console.log('checkPayStack');
        if(this.paystackSuccessToken != null){
          if(this.recentTickets.indexOf(this.paystackSuccessToken) < 0){
              this.viewService.notify('success','Transaction Successful',5000);
              this.recentTickets.push(this.paystackSuccessToken);
               this.crudService.localUpdate('recentTickets',this.recentTickets);
               this.viewService.loadPageForTicket(6);
           }
        }
      }
     }
    });
   }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


  slidesDidLoad(slides: IonSlides): void {
  //  slides.startAutoplay();
  }

  ionViewWillEnter() {
    this.viewService.setPageType('front-end');
    this.menuCtrl.enable(false);
  }



  doRefresh(event) {
    console.log('Begin async operation');

    setTimeout(() => {
      console.log('Async operation has ended');
      event.target.complete();
    }, 2000);
  }



  getNetworkError(evt) {
    console.log(evt);

    this.networkError = evt;
  }

  homeAction(link) {
    console.log(link);

    if(link.type === 'link'){
      this.router.navigate([link.url]);
    }
      else {
        if(link.url === '/support'){
         this.viewService.showCustomerService = true;
        }else if(link.url === '/call-to-sell'){
          this.viewService.showCallToSellModal = true;
         }else{
          this.openPopupModal(link.url);
        }
      }
  }

  openPopupModal(value){
    this.openViewModal = true;
  }

  sanitizeUrl(url){
    return this.sanitize.bypassSecurityTrustResourceUrl(url);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    // Update header background color on window scroll
    this.updateHeaderBackground();
  }

  updateHeaderBackground() {
    // Get the current scroll offset from the ion-content element
    this.content.getScrollElement().then((scrollElement) => {
      const scrollTop = scrollElement.scrollTop;
      console.log(scrollTop);

      // Calculate a threshold value (you can adjust this as needed)
      const threshold = 100; // When the user scrolls 100 pixels down, change the header background

      // Calculate the alpha value (opacity) for the header background
      const alpha = Math.min(1, scrollTop / threshold);

      // Set the header background color dynamically
      this.headerStyles['background-color'] = `rgba(255, 0, 0, ${alpha})`; // Replace '255, 0, 0' with the desired color

      // Trigger Angular change detection to apply the new styles
      // This might not be necessary depending on your setup, but it ensures the styles are updated immediately
      // if there are any issues with change detection
      // import { ChangeDetectorRef } from '@angular/core';
      // constructor(private cdr: ChangeDetectorRef) {}
      // this.cdr.detectChanges();
    });
  }

  async openTypeform() {
   // createPopup('ox2ySO7u', { size: 90 }).open(); // call open() on created popup
   const modal  = await this.modaCtrl.create({
    component: WaitlistFormComponent,

   });

  modal.present();
  }

  async openAccessModal(){
    this.accessModalTriggerCount += 1;
    console.log(this.accessModalTriggerCount);
    if(this.accessModalTriggerCount >= 3){
      console.log('reached');
      this.showAccessModal = true;
     const alert = await this.alertCtrl.create({
       header: 'Access',
       message: 'Enter Access Code',
       inputs: this.alertInputs,
       buttons: this.alertButtons,
     });
      await alert.present();
      this.accessModalTriggerCount = 0;
    }
  }

  cancel() {
    this.modaCtrl.dismiss(null, 'cancel');
  }

  confirm() {
    //his.modaCtrl.dismiss(this.name, 'confirm');
  }

  onWillDismiss(event: Event) {
    const ev = event as CustomEvent<any>;
    if (ev.detail.role === 'confirm') {
     // this.message = `Hello, ${ev.detail.data}!`;
    }
  }
}
