/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable quote-props */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { of, Subject } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { UtilService } from '../util/util.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService{
  user: any;
  userNotes: any;
  handleNotes: any;
  noteList: any = [];
  unReadList: any = [];
  hasReadList: any = [];
  totalNotes: any;
  entityName = 'notes';
  snapshot: any = [];
  noteList$ = new Subject();

  constructor(private util: UtilService,private http: HttpClient) {  }

  ionViewWillEnter() {
   // this.getUserNotes().then((res) => {});
  }

  playAudio() {
    const audio = new Audio();
    audio.src = '/assets/sounds/' + environment.app.noteSoundFile;
    audio.load();
    audio.play().then(
      () => {
        // console.log('hello');
      },
      (error) => {}
    );
  }

  setFavicon(favicon) {
    const favIcon: HTMLLinkElement = document.querySelector('#appIcon');
    favIcon.href = favicon;
  }

  sendTextMessage(phoneNumber,message) {
    const data = {
       // eslint-disable-next-line @typescript-eslint/naming-convention
      sender_id: 'jama',
      msg: message,
      to: phoneNumber,
    };
    return this.http.post(environment.app.apiurl, data );
  }

  sendVerificationCode(phoneNumber){
    const data = {
     to: phoneNumber,
   };
    return this.http.post(environment.app.apiurl+'api/sendVerificationCode', data );
   }


   sendLuckySingUpCode(phoneNumber){
    // const code = this.generateOTP(4);
    // const data = {key:'wHC9DuLXRgLRQDeLJpzqLcpytWGhoLFVth46MQ4E7PvXt',to:phoneNumber,msg:'Your lucky code is: '+code,sender_id:'jama'};
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Access-Control-Allow-Origin':'*',
    //     'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    //   }),
    //   params:data
    // };
    // const baseurl = 'https://apps.mnotify.net/smsapi';
    // // eslint-disable-next-line @typescript-eslint/naming-convention
    // return this.http.get(baseurl,httpOptions);
   }


   generateOTP(size) {
    const zeros = '0'.repeat(size - 1);
    const x = parseFloat('1' + zeros);
    const y = parseFloat('9' + zeros);
    const confirmationCode = String(Math.floor(x + Math.random() * y));
    return confirmationCode;
}
}


