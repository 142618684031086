import { Component } from '@angular/core';
import { SvgLoaderService } from './svg-loader.service';

@Component({
  selector: 'app-svg-loader',
  template: `
    <svg
      id="mySVG"
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      height="200"
      viewBox="0 0 100 100"
      (load)="onSvgLoad()"
    >
      <path
        d="M50,10 A40,40 0 1,1 50,90 A40,40 0 1,1 50,10"
        stroke="#3498db"
        stroke-width="10"
        fill="transparent"
      />
    </svg>

    <button (click)="loadSvg()">Load SVG</button>
    <div *ngIf="isLoading$ | async">Loading...</div>
  `,
})
export class SvgLoaderComponent {
  isLoading$ = this.svgLoaderService.isLoading$;
  constructor(private svgLoaderService: SvgLoaderService) {}

  loadSvg() {
    this.svgLoaderService.startLoading();

    // Simulate loading for 3 seconds (you can replace this with actual loading logic)
    setTimeout(() => {
      this.svgLoaderService.endLoading();
    }, 3000);
  }

  onSvgLoad() {
    // The SVG has fully loaded, you can perform actions here
    console.log('SVG is fully loaded');

    // For example, you can access and manipulate elements within the SVG
    const pathElement = document.querySelector('#mySVG path') as SVGPathElement;
    pathElement.style.fill = 'red';
  }
}


