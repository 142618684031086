export const environment = {
  production: false,
  domain:'https://remotedev-africa.web.app',
  firebaseConfig :{
    apiKey: 'AIzaSyCNw9cQLS_hNSdyvF71zptuD56w14D9Ux4',
    authDomain: 'remotedev-africa.firebaseapp.com',
    projectId: 'remotedev-africa',
    storageBucket: 'remotedev-africa.appspot.com',
    messagingSenderId: '287206181923',
    appId: '1:287206181923:web:baf13abea65f183756f596',
    measurementId: 'G-VYR9ERR24Z'
  },
  app:{
    name:'The Luckiest Africa',
    noteSoundFile: '../../assets/sounds/note-sound.wav',
    chatSoundFile: '../../assets/sounds/chat-sound.wav',
    faviconFile: '../../assets/icon/favicon.png',
    noteFaviconFile: '../../assets/icon/favicon_note.png',
    defaultImage:'../../assets/placeholder-banner.png',
    apiurl:'http://localhost:3000/'
  },
  profile:{
    contactItemsPersistenceType:'confirm', //confirm,save
    allowPasswordChange:true,
    allowEmailChangeBySave:false,
    allowPhoneChange:true,
    allowAddressChange:true,
    allowNameChange:true,
    allowProfilePictureChange:true,
  }

};
