import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fortune-wheel',
  templateUrl: './fortune-wheel.component.html',
  styleUrls: ['./fortune-wheel.component.scss'],
})
export class FortuneWheelComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
