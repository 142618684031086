import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-single-ticket',
  templateUrl: './single-ticket.component.html',
  styleUrls: ['./single-ticket.component.scss'],
})
export class SingleTicketComponent implements OnInit {
@Input() ticket: any;
@Input() singleView = false;
showErrorResponse = false;
ticketId: any;
apiCalled = false;
todaysDate = new Date();


  constructor() { }

  ngOnInit() {}

}
