import { LuckiestAuthService } from './../../apps/theluckiestafrica/services/luckiest-auth.service';
import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AlertController,
  IonAccordionGroup,
  LoadingController,
  MenuController,
  ModalController,
  NavController,
  NavParams,
  ToastController,
} from '@ionic/angular';
import { Subscription } from 'rxjs';
import { CrudService } from 'src/app/services/crud/crud.service';
import { LocationService } from 'src/app/services/location/location.service';
import { ViewService } from 'src/app/services/view/view.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {

  @ViewChild('accordionGroup', { static: true }) accordionGroup: IonAccordionGroup;
  @Input() public actionObject: any;
  @Input() public presentAs: 'modal' | 'inline' = 'modal';
  viewMode: any = 'social-login';
  onLoginForm: FormGroup;
  onCofirmOTPForm: FormGroup;
  singleUserArray: any;
  loginError: string;
  authError: any;
  showLoader: any;
  presentConfirm = false;

  onRegisterForm: FormGroup;
  onPassResetForm: FormGroup;
  onPassResetFormAction: FormGroup;
  regReturnData: any;
  regError: any;
  sessionVars: any;
  matchingPasswordsGroup: any;

  phoneNumber: any;
  displayCaptcha: any = 'none';
  geolocationData: any = {};
  defaultCountryCode: any;
  showOTPInput = true;
  selectedCountry: any;
  selectedCountryFlag: any;
  mobNumberPattern =
    '/^[(]{0,1}[0-9]{3}[)]{0,1}[-s.]{0,1}[0-9]{3}[-s.]{0,1}[0-9]{4}$/';

  wantsToLoginWithCredentials: boolean;
  email: string;
  password: string;
  error: string;
  confirmMessage;
  confirmError;
  socialLoginError;
  emailVerified;

  xlocations: any;
  xlocation: any;
  ylocations: any;
  ylocation: any;
  locationSubscription: Subscription;
  fuseOptions = {
    keys: [
      {
        name: 'name',
        weight: 0.3,
      },
      {
        name: 'dial_code',
        weight: 0.5,
      },
      {
        name: 'code',
        weight: 0.2,
      },
    ],
  };
  page: any = 1;

  loadLogin: any = false;
  authViewMode: any = null;
 // authType: any = 'login';
  authType: any;;

  passwordRecoverEmail: any;

  passwordLinkJustSent: any;
  passResetError: any;
  passResetCode: any = true;
  passwordJustReset: any;
  action = null;
  actionError: any;
  recaptchaVerifier: any;
  loadingCaptcha = false;
  recaptchaConfirmationResult;
  passedMessageData: any;

  passWordResetStep = 'sendOTP';
 //passWordResetStep = 'enterOTP';
 otpPrefix = 'XYZ';
 //passWordResetStep = 'enterNewPassword';
 passInputType = true;

  leadingTo = null;

  geoLocation: any = {};
  currentFlag: any = 'https://ipgeolocation.io/static/flags/gh_64.png';
  countryObject: any =   {name:'Ghana',code:'+233',flag:'gh'};
  flagsRootUrl = 'https://ipgeolocation.io/static/flags/';
  dialCode = '';
  countryCode = '';
  showCountrySelector = false;

  constructor(
    public navCtrl: NavController,
    public menuCtrl: MenuController,
    public toastCtrl: ToastController,
    public alertCtrl: AlertController,
    public loadingCtrl: LoadingController,
    public modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private ngZone: NgZone,
    private locService: LocationService,
    private router: Router,
    private aroute: ActivatedRoute,
    public viewService: ViewService,
    private crud: CrudService,
    private el: ElementRef,
    private luckAuth: LuckiestAuthService,
    private navParams: NavParams
  ) {}

  async ngOnInit() {


    //this.currentFlag = this.flagsRootUrl + this.countryObject.flag + '_64.png';


    this.locService.getGeoLocation().then((result) => {
      console.log(result);

      this.geoLocation = result;
      this.dialCode = this.geoLocation.calling_code;
      this.countryCode = this.geoLocation.country_code2;
      this.currentFlag = this.geoLocation.country_flag;
      // eslint-disable-next-line max-len
      this.crud.localUpdate('selectedCountry',{name:result.name, dialcode:result.calling_code,flag:this.flagsRootUrl + this.countryObject.flag + '_64.png',countryCode:result.country_code2});
      if(!result){
        this.crud.localGet('selectedCountry').then((res: any) => {
          console.log(res);
          if(res !== null && res !== undefined) {
           this.countryObject = res;
           this.currentFlag = this.countryObject.flag;
           this.dialCode = this.countryObject.dialCode;
           this.countryCode = this.countryObject.countryCode;
          }
         });
       // this.crud.localDelete('selectedCountry');
      }
    }).catch(reason=>{
      console.log(reason);
    });


    this.authType = 'register';
    this.switchAuthType('register','login-options');
    this.switchViewMode('login-options');
    // this.luckAuth.deleteAccount('0548106773','michael.okohwame@gmail.com').subscribe(res=>{
    //   console.log(res);
    // });

    this.crud.localGet('signupData').then((result: any) =>{
      if(result !== null ){
        this.onRegisterForm.patchValue(result);
       //this.onRegisterForm.get('passwordConfirm').setValue(result.password);
        if(result.mobileNumber){
         // this.switchAuthType('register','confirmOTP');
         //
        // this.switchAuthType('register','login-options');
        }else{
         // this.switchAuthType('register','login-options');
        }
      }
    });

    // this.switchAuthType('register','confirmOTP');
    // this.showOTPInput = true;

   this.action = null;

   this.leadingTo = this.navParams.data.leadingTo;

  if(this.viewService.quickReauthOption){
      //this.authViewMode = this.viewService.quickReauthOption;

      this.passedMessageData = this.viewService.passedMessageData.userdata;
      if(this.passedMessageData){
        this.phoneNumber = this.passedMessageData.phone;
      }
      //console.log(this.phoneNumber);
     // this.menuCtrl.enable(true);
  }else{
   // this.menuCtrl.enable(false);
  }

    this.onLoginForm = this.formBuilder.group({
      mobileNumber: [null, Validators.compose([Validators.pattern('[- +()0-9]+'),
      Validators.required,])],
      password: [null, Validators.compose([Validators.required])],
    });

    this.onRegisterForm = this.formBuilder.group(
      {
        name: ['',Validators.compose([Validators.required,Validators.min(6)])],
        mobileNumber: ['', Validators.compose([
          Validators.maxLength(20),
          Validators.pattern(
            '[- +()0-9]+'
           ),
          Validators.required,
        ]),],
        email: [
          '',
          Validators.compose([
            Validators.maxLength(255),
            Validators.pattern(
              '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'
            ),
            Validators.required,
          ]),
        ],
        password: [
          '',
          Validators.compose([
            Validators.required,
            Validators.min(6),
          ]),
        ],
        passwordConfirm: ['',
        Validators.compose([
          Validators.required,
          Validators.min(6),
        ]),],
        tos: [null,
        Validators.compose([
          Validators.requiredTrue,
        ]),],
      },
      { validator: this.matchingPasswords('password', 'passwordConfirm') }
    );

    this.onPassResetForm = this.formBuilder.group({
      passwordRecoverNumber: [
        null,
        Validators.compose([ Validators.pattern(
          '[- +()0-9]+'
         ), Validators.required])
      ],
    });
    ///check if use is logged in

    this.onCofirmOTPForm = this.formBuilder.group({
      otp: [null, Validators.compose([Validators.required]),
      ],
    });


    this.onPassResetFormAction = this.formBuilder.group(
      {
        password: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(6000),
          ]),
        ],
        passwordConfirm: ['', Validators.required],
      },
      { validator: this.matchingPasswords('password', 'passwordConfirm') }
    );

    this.crud.localGet('sessionVars').then((session) => {
      if (session) {
        //session = JSON.parse(session);
        if (this.geolocationData && session.hasOwnProperty('geoData')) {
          this.geolocationData = session.geoData;
          this.defaultCountryCode = this.geolocationData.dial_code;

          this.processCountryCode(this.defaultCountryCode);
          this.crud.localUpdate('geoLocationData', this.geolocationData);
        }
      } else {
        // if not fall back on the location data in local storage
        //   this.storage.remove('geoLocationData');
        this.crud.localGet('geoLocationData').then((geodata) => {
          if (geodata > 0) {
            this.geolocationData = geodata.value;

            this.defaultCountryCode = this.geolocationData.dial_code;
            this.processCountryCode(this.defaultCountryCode);
          } else {
            //if both fail then make a new request to the api and store in local storage
            this.locService.getGeoLocation().then((res) => {
              const gdata = res;
              //console.log(gdata);
              this.geolocationData = {
                country: gdata.country_name,
                countryCode: gdata.calling_code,
                code: gdata.country_code2,
                code2: gdata.country_code3,
                city: gdata.city,
                continent: gdata.continent_name,
                ip: gdata.ip,
                stateProv: gdata.state_prov,
                coordinate: { lat: gdata.latitude, lon: gdata.longitude },
              };

              this.defaultCountryCode = this.geolocationData.dial_code;
              //this.processCountryCode(this.default_country_code);
              this.crud.localUpdate('geoLocationData', this.geolocationData);
            });
          }
        });
      }
    });
   // this.toggleAccordion('login-options');
  }







  toggleAccordion(index){
    const nativeEl = this.accordionGroup;
      nativeEl.value = index;
  };

  switchAuthType(authType, authViewMode) {
    this.authType = authType;
    this.toggleAccordion(authViewMode);
    this.regError = '';
    this.authViewMode = authViewMode;
    setTimeout(() => {
      this.authError = '';
    }, 1000);
  }

  ionViewWillLeave() {
    this.loadLogin = false;
    this.showLoader = false;
    this.displayCaptcha = false;
    this.authViewMode = false;
    this.viewService.dismissLoader();
  }

  /*  async seeInfo(title,info){
    const modal =  await this.modalCtrl.create({
    component:InfosPage,
    componentProps : {title:title,content:info}
    });
    modal.present();
  }*/

  openCountryList() {
    // this.selectable.open();
  }



  processCountryCode(value) {
    if(value){
    const ff = value.split('+');
    const ccode = ff[ff.length - 1];
    const cInfo = this.ylocations.filter((x) => x.dial_code === '+' + ccode);
    this.selectedCountry = cInfo[0];
    const name = this.selectedCountry.name.toLowerCase();
    this.selectedCountryFlag =
      '/assets/intl/' + name.replace(' ', '-') + '.svg';
    }
  }


  filterLocations(locations, text: string) {
    return locations.filter(
      (location) =>
        location[0].toLowerCase().indexOf(text) !== -1 ||
        location[0].toLowerCase().indexOf(text) !== -1
    );
  }


  matchingPasswords(passwordKey: string, confirmPasswordKey: string) {
    return (group: FormGroup): { [key: string]: any } => {
      const password = group.controls[passwordKey];
      const confirmPassword = group.controls[confirmPasswordKey];

      if (password.value !== confirmPassword.value) {
        return {
          mismatchedPasswords: true,
        };
      }
    };
  }

  ionViewDidLeave() {
    this.showLoader = false;
  }

  reInitAuthError(event) {
    this.authError = '';
   // console.log(this.onRegisterForm.value);
  }

  switchViewMode(view) {
    this.viewMode = view;
    this.authError = '';
    this.regError = '';
  }

  goTo(url) {
    this.navCtrl.navigateForward(url);
  }

  async loadPage(duration) {
    const loader = await this.loadingCtrl.create({
      duration,
    });
    loader.present();
  }

  async finishPageLoad() {
    if (this.loadingCtrl.getTop()) {
      const loader = await this.loadingCtrl.dismiss();
    }
  }


  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  async openLoader() {
    const loading = await this.loadingCtrl.create({
      message: 'Please Wait ...',
      duration: 2000,
    });
    await loading.present();
  }
  async closeLoading() {
    return await this.loadingCtrl.dismiss();
  }

  async dismissModal() {
    const isModalOpened = await this.modalCtrl.getTop();
    if (isModalOpened) {
      this.modalCtrl.dismiss(this.passedMessageData);
    }
  }

  async presentToast(content, time) {
    const toast = await this.toastCtrl.create({
      message: content,
      duration: time,
      cssClass: 'mini-toast',
      color: 'success',
      position: 'top',
    });
    toast.present();
  }





    login(){
      this.loadPage(4000);
    const mobileNumber = this.onLoginForm.get('mobileNumber').value;
    const password = this.onLoginForm.get('password').value;
    console.log(mobileNumber,password);

   this.luckAuth.login(mobileNumber,password,this.countryCode).subscribe((result: any)=>{
    console.log(result);
      if(result.status === true){
        this.luckAuth.sessionVars = result.data;
        this.crud.localInsert('sessionVars',this.luckAuth.sessionVars);
        if(!this.leadingTo){
        setTimeout(() => {
          this.router.navigateByUrl('/tickets');
        }, 500);
         }
        this.viewService.notify('success','Login Successful',2000);
        this.modalCtrl.getTop().then(res=>{
          if(res){
            this.modalCtrl.dismiss(this.leadingTo);
             }
        });
       }
      },error=>{
        console.log(error);
        this.authError = 'Login Failed! Either password or Phone Number is incorrect';
        this.viewService.dismissLoader();
      });
    }


    register(otp: any = null){
      if(otp){
        otp.code = otp && otp.data || null;
        if(otp.data.length < 6){
          return;
        }
      }

    this.loadPage(2000);
    const phoneNumber = this.onRegisterForm.get('mobileNumber').value;
    const mobileNumber = phoneNumber.toString().indexOf(this.dialCode) > -1 ? phoneNumber : this.dialCode+ phoneNumber;
    const password = this.onRegisterForm.get('password').value;
    const name = this.onRegisterForm.get('name').value;
    const email = this.onRegisterForm.get('email').value;
    const payload = {name,email, mobileNumber, password};
    console.log(payload);
    this.crud.localInsert('signupData',payload);
    if(otp && otp.code){
    this.luckAuth.register(name,email,mobileNumber,password,otp.code.toString(),this.countryCode).subscribe((result: any)=>{
      if(result.status === true){
        this.luckAuth.sessionVars = result.data;
        this.crud.localInsert('sessionVars',this.luckAuth.sessionVars);
        if(!this.leadingTo){
          setTimeout(() => {
            this.router.navigateByUrl('/tickets');
          }, 500);
           }
        this.viewService.notify('success','Your Account has been created successfully',2000);
        this.modalCtrl.getTop().then(res=>{
          if(res){
            this.modalCtrl.dismiss(this.leadingTo);
             }
        });
       }
      },error=>{
        if(error.error && error.error.message){
          this.authError = error.error.message || error.message;
          this.viewService.notify('warning',this.authError,2000);
        }else if(error.message){
          this.authError = error.message;
          this.viewService.notify('warning',this.authError,2000);

        }else{
          this.authError = 'Something went wrong: Either You account exist already or your details are invalid';
        }
        this.viewService.dismissLoader();
      });} else if(!otp){
        this.luckAuth.getRegisterOTP(mobileNumber,email,this.countryCode).subscribe((result: any)=>{
          if(result.status === true){
            this.viewService.notify('success','OTP has been sent to your phone number '+mobileNumber,3000);
            this.switchAuthType('auth','confirmOTP');
            this.showOTPInput = true;
            this.otpPrefix = result && result.data.prefix;
           }else{
            this.viewService.notify('danger','Failed to send OTP to phoneNumber',4000);
           }
        },error=>{
          console.log(error);
          this.authError = error.error.message || error.message;
          this.viewService.notify('danger', this.authError,4000);
        });
      }
   }


    sendPassResetOTP(event){
      //897683
    this.loadPage(4000);
    const mobileNumber = this.dialCode+this.onPassResetForm.get('passwordRecoverNumber').value;
    this.luckAuth.sendPassResetOTP(mobileNumber).subscribe((result: any)=>{
      if(result.status === true){
        this.viewMode = 'reset';
        this.viewService.notify('success','OTP has sent to your Phone Number',2000);
        setTimeout(() => {
          this.passWordResetStep = 'enterOTP';
        }, 2000);
       }
      },error=>{
        this.authError = 'Failed to send OTP. Check your phone number and try again';
        this.viewService.dismissLoader();
      });
    }

    confirmResetOTP(event){
    const mobileNumber = this.dialCode+this.onPassResetForm.get('passwordRecoverNumber').value;
    const otp = event.data;
    if(otp.length >= 6){
      this.loadPage(4000);
    this.luckAuth.confirmResetOTP(mobileNumber,otp).subscribe((result: any)=>{
      if(result.status === true){
        this.viewService.notify('success','OTP has been confirmed',2000);
        setTimeout(() => {
          this.passWordResetStep = 'enterNewPassword';
        }, 2000);
       }else{
        this.authError = result.message;
        this.viewService.dismissLoader();
       }
      },error=>{
        this.authError = 'Failed To Validate OTP';
        this.viewService.dismissLoader();
      });
     }
    }

    resetPassword(){
      this.loadPage(4000);
    const mobileNumber = this.dialCode+this.onPassResetForm.get('passwordRecoverNumber').value;
    const password = this.onPassResetFormAction.get('password').value;
    this.luckAuth.resetPassword(mobileNumber,password).subscribe((result: any)=>{
      if(result.status === true){
        this.viewService.notify('success','Password has been reset successfully',2000);
        //this.passWordResetStep = 'login';
        setTimeout(() => {
          this.onLoginForm.get('mobileNumber').setValue(mobileNumber);
          this.onLoginForm.get('password').setValue(password);
        }, 2000);
        this.viewMode = 'email-login';
        this.authType = 'login';
        setTimeout(() => {
          this.login();
        }, 3000);
       }else{
        this.authError = result.message;
        this.viewService.dismissLoader();
       }
      },error=>{
        this.authError = 'Failed To Reset Password';
        this.viewService.dismissLoader();
      });
    }

ionWillLeave(){
  this.displayCaptcha = false;
}
async phoneLoginSMSPrompt(confirmationResult) {
  this.displayCaptcha = 'none';
  const prompt = await this.alertCtrl.create({
    // title: 'Enter the Confirmation code',
    inputs: [{ name: 'confirmationCode', placeholder: 'Confirmation Code' }],
    buttons: [
      {
        text: 'Cancel',
        handler: (data) => {
          this.loadLogin = false;
        },
      },
      {
        text: 'Confirm',
        handler: (data) => {
          confirmationResult
            .confirm(data.confirmationCode)
            .then((result) => {
              // User signed in successfully.
              // console.log(result.user);

              setTimeout(() => {
                // console.log('phone_number_verified');
               // this.authProcede(result.user, 'phone_auth', true);
               console.log(result.user);

              }, 2000);

              // ...
            })
            .catch((error) => {
              // console.log(error);
              this.loadLogin = false;
              this.authError = 'Error :' + error.message;
              // User couldn't sign in (bad verification code?)
              // ...
            });
        },
      },
    ],
  });
  await prompt.present();
}


setCountry(e){
  //this.countryObject = res;
     this.currentFlag = e.flag;
     this.dialCode = e.dialCode;
     this.countryCode = e.code;
     this.crud.localInsert('selectedCountry',{name:e.name,flag:e.flag,dialCode:e.dialCode,countryCode:e.code});
     this.showCountrySelector = false;
 }

 openCountrySelector(){
  this.showCountrySelector = true;
 }
}
