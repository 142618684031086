import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class RouteService {
  routeList: any = [];
  routeTitle: any;
  routeDirection: any = 1;
  currentUrl: any;

  public previousUrl: any;

  constructor(public router: Router, private navCtrl: NavController) {
    this.currentUrl = this.router.url;
    console.log(this.router.url);
    this.router.events.subscribe(event => {
     if (event instanceof NavigationEnd) {
       this.previousUrl = this.currentUrl;
       this.currentUrl = event.url;
     };
   });
  }

  public getPreviousUrl(){
    return this.previousUrl;
  }

  goToPrevious() {
    this.routeDirection = -1; //backward
    this.navCtrl.back();
    setTimeout(() => {
      this.routeList.pop();
    }, 600);

    if (this.routeList.length === 2) {
      // this.routeList.pop();
    }
  }

  setPreviousUrl(previousUrl: string) {
    this.previousUrl.next(previousUrl);
  }

  goTo(url) {
    this.router.navigateByUrl(url);
  }
}
