export const environment = {
  production: true,
  domain: window.location.origin,
  firebase:{
    apiKey: 'AIzaSyCNw9cQLS_hNSdyvF71zptuD56w14D9Ux4',
    authDomain: 'remotedev-africa.firebaseapp.com',
    projectId: 'remotedev-africa',
    storageBucket: 'remotedev-africa.appspot.com',
    messagingSenderId: '287206181923',
    appId: '1:287206181923:web:baf13abea65f183756f596',
    measurementId: 'G-VYR9ERR24Z'
  },
  app:{
    name:'The Luckiest Africa',
    noteSoundFile: '../../assets/sounds/note-sound.wav',
    chatSoundFile: '../../assets/sounds/chat-sound.wav',
    faviconFile: '../../assets/icon/favicon.png',
    noteFaviconFile: '../../assets/icon/favicon_note.png',
    defaultImage:'../../assets/placeholder-banner.png',
    //apiurl:'http://localhost:8080/https://theluckiestafrica.com/api/'
    apiurl:'https://api.theluckiestafrica.com/api'
    //apiurl:'https://e681-2601-681-6601-5b00-f0c1-5d1c-1818-eb34.ngrok-free.app/api'
  },
  profile:{
    contactItemsPersistenceType:'save', //confirm,save
    allowPasswordChange:true,
    allowEmailChangeBySave:false,
    allowPhoneChange:true,
    allowAddressChange:true,
    allowNameChange:true,
    allowProfilePictureChange:true,
  }

};
