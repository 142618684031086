import { NgModule } from '@angular/core';
import {RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { activeApp } from './app-config';

const routes: Routes = [
      // {
    //   path: '',
    //   pathMatch: 'full',
    //   loadChildren: () => import('./pages/community-draw/community-draw.module').then( m => m.CommunityDrawPageModule)
    // },
  {
    path: 'community-draw',
    loadChildren: () => import('./pages/community-draw/community-draw.module').then( m => m.CommunityDrawPageModule)
  },
  {
    path: 'paystack-checkout-success/:paystack_checkout_id',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)  },
  {
    path: 'tickets',
    loadChildren: () => import('./apps/theluckiestafrica/pages/tickets/tickets.module').then(
       m => m.TicketsPageModule), canActivate: [AuthGuardService]
  },
  {
    path: 'about-us',
    loadChildren: () => import('./pages/about-us/about-us.module').then( m => m.AboutUsPageModule)
  },
  {
    path: 'scratch-cards',
    loadChildren: () => import('./pages/scratch-cards/scratch-cards.module').then( m => m.ScratchCardsPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'terms-of-service',
    loadChildren: () => import('./pages/terms-of-service/terms-of-service.module').then( m => m.TermsOfServicePageModule)
  },
  {
    path: 'list/:type',
    loadChildren: () => import('./pages/admin/transaction-history/transaction-history.module').then( m => m.TransactionHistoryPageModule)
  },
  {
    path: 'pairings',
    loadChildren: () => import('./pages/pairings/pairings.module').then( m => m.PairingsPageModule)
  },
  {
    path: 'communities',
    loadChildren: () => import('./pages/communities/communities.module').then( m => m.CommunitiesPageModule)
  },
  {
    path: 'vendor-home',
    loadChildren: () => import('./pages/vendor-home/vendor-home.module').then( m => m.VendorHomePageModule)
  },
  {
    path: 'vendor-dashboard',
    loadChildren: () => import('./pages/vendor-dashboard/vendor-dashboard.module').then( m => m.VendorDashboardPageModule)
  },
  {
    path: ':id',
    loadChildren: () => import('./apps/theluckiestafrica/pages/view-ticket/view-ticket.module').then( m => m.ViewTicketPageModule)
  },
];

if(activeApp.key === 'vendor-portal'){
  routes.unshift({
      path: '',
      pathMatch: 'full',
      loadChildren: () => import('./pages/vendor-home/vendor-home.module').then( m => m.VendorHomePageModule)
    });
    routes.unshift({
      path: 'home',
      pathMatch: 'full',
      loadChildren: () => import('./pages/vendor-home/vendor-home.module').then( m => m.VendorHomePageModule)
    });
}else{

  routes.unshift({
      path: '',
      pathMatch: 'full',
      loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
    });

  routes.unshift({
      path: 'home',
      pathMatch: 'full',
      loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
    });
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
