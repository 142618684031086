import { Component, OnInit } from '@angular/core';
import { ViewService } from 'src/app/services/view/view.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  phoneNumbers = [
 '(+233) 50 095 9908',
 '(+233) 53 599 2663',
 '(+233) 53 599 7343',
 '(+233) 53 661 8195',
 '(+233) 55 010 4946'
  ];
  currentYear = 2024;

  constructor(public viewService: ViewService) { }

  ngOnInit() {
   this.currentYear = new Date().getFullYear();
  }

}
