import { ViewService } from 'src/app/services/view/view.service';
import { LuckiestAuthService } from './../../apps/theluckiestafrica/services/luckiest-auth.service';
import { Component, Input, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app/app.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { RouteService } from 'src/app/services/route/route.service';
import { CommunityService } from 'src/app/services/communities/communities.services';

@Component({
  selector: 'app-util-menu-bar',
  templateUrl: './util-menu-bar.component.html',
  styleUrls: ['./util-menu-bar.component.scss'],
})
export class UtilMenuBarComponent implements OnInit {
  @Input() textColor;
  constructor(
    public route: RouteService,
    public noteService: NotificationService,
    public appService: AppService,
    public luckAuth: LuckiestAuthService,
    public viewService: ViewService,
    public communityService: CommunityService
  ) {}

  ngOnInit() {}
}
