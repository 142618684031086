import { ViewService } from './../../../../services/view/view.service';
import { LuckiestAuthService } from './../../services/luckiest-auth.service';
import { CrudService } from './../../../../services/crud/crud.service';
import { SelectFlagComponent } from './../select-flag/select-flag.component';
import { ModalController, MenuController } from '@ionic/angular';
import { LocationService } from './../../../../services/location/location.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CommunityService } from 'src/app/services/communities/communities.services';
import { TicketGeneratorComponent } from '../ticket-generator/ticket-generator.component';

@Component({
  selector: 'app-uni-header',
  templateUrl: './uni-header.component.html',
  styleUrls: ['./uni-header.component.scss'],
})
export class UniHeaderComponent implements OnInit {

  @ViewChild('ticketGenerator', {static : false}) ticketGenerator: TicketGeneratorComponent;

  geoLocation: any = {};
  currentFlag: any = 'https://ipgeolocation.io/static/flags/gh_64.png';
  countryObject: any =   {name:'Ghana',code:'+233',flag:'gh'};
  flagsRootUrl = 'https://ipgeolocation.io/static/flags/';
   textClass = 'text-white';

   currentPage = 1;
   itemsPerPage = 50;
   paginatedData: any[] = [];
   communityObjects = [];
  constructor(private locService: LocationService,
    private modalCtrl: ModalController,
    private crud: CrudService,
    private menuCtrl: MenuController,
    public  luckAuth: LuckiestAuthService,
    public viewService: ViewService,
    public communityService: CommunityService,
    private route: Router
    ) {

    this.communityObjects = this.communityService.communityObjects;
     }


     selectCommunity(community){
      this.communityService.selectedCommunity = community;
      this.viewService.initTicketGenerator(1,true).then((res)=>{
        this.communityService.showMegaMenu = false;
      });

     }

  ngOnInit() {
    this.paginateArray();
  }

  toggleMenu() {
    this.menuCtrl.toggle();
    this.menuCtrl.enable(true);
  }

 async openFlagsModal() {
    const modal = await this.modalCtrl.create({
      component: SelectFlagComponent,
      componentProps: {},
      cssClass: 'select-flag-modal',
      initialBreakpoint:0.5
    });

    modal.onDidDismiss().then((res) => {
       this.countryObject = res.data;
       this.crud.localUpdate('selectedCountry',this.countryObject);
       this.currentFlag = this.flagsRootUrl + this.countryObject.flag + '_64.png';

    }).catch((err) => {});

    modal.present();

  }

  paginateArray(): void {
    this.communityObjects = this.communityObjects.filter((communityObject) => communityObject.community).
    sort((a,b) => a.community - b.community);

    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.paginatedData = this.communityObjects.slice(startIndex, endIndex);
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    this.paginateArray();
  }

  getPages(): number[] {
    const pageCount = Math.ceil(this.communityService.communityObjects.length / this.itemsPerPage);
    return Array.from({ length: pageCount }, (_, index) => index + 1);
  }

  search(event){
  const evalue = event.detail.value.toLowerCase();
  if(evalue.length){
  const filterResults = this.communityObjects.filter(
    (communityObject) => communityObject.name.replaceAll(' ').toLowerCase().includes(evalue));
    console.log(filterResults);
    this.paginatedData = filterResults;
    this.paginatedData = this.paginatedData.slice(0, 50);
  }else{
   this.communityObjects = this.communityService.communityObjects;
   this.paginateArray();
  }
  }

}
