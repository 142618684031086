import { PlayButtonComponent } from './../apps/theluckiestafrica/components/play-button/play-button.component';
import { UtilMenuBarComponent } from './util-menu-bar/util-menu-bar.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LogoComponent } from './logo/logo.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { NothingToSeeComponent } from './nothing-to-see/nothing-to-see.component';
import { TimeAgoPipe } from '../pipes/time-ago.pipe';
import { SignupComponent } from './signup/signup.component';
import { InputMaskComponent } from './input-mask/input-mask.component';
import { CountrySelectComponent } from './country-select/country-select.component';
import { FooterComponent } from './footer/footer.component';
import { WaitlistFormComponent } from './waitlist-form/waitlist-form.component';
import { SvgLoaderComponent } from './svg-loader/svg-loader.component';

@NgModule({

  imports: [
    IonicModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatTableModule,
    DragDropModule,
    MatPaginatorModule,
  ],
  declarations: [
   LogoComponent,
   NothingToSeeComponent,
   TimeAgoPipe,
   SignupComponent,
   UtilMenuBarComponent,
   PlayButtonComponent,
   InputMaskComponent,
   CountrySelectComponent,
   FooterComponent,
   WaitlistFormComponent,
   SvgLoaderComponent
  ],
  exports: [
    LogoComponent,
   NothingToSeeComponent,
   TimeAgoPipe,
   SignupComponent,
   UtilMenuBarComponent,
   PlayButtonComponent,
   InputMaskComponent,
   CountrySelectComponent,
   FooterComponent,
   WaitlistFormComponent,
   SvgLoaderComponent
  ]
})
export class ComponentsModule { }
