import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as uuid from 'uuid';
const myId = uuid.v4();
import { Storage } from '@capacitor/storage';
@Injectable({
  providedIn: 'root',
})
export class CommunityService {
  showMegaMenu = false;
  communityObjects: any = [
    {
        name: 'Doctors ',
        selected: false,
        community: 41
    },
    {
        name: 'Suame Magazine ',
        selected: false,
        community: 78
    },
    {
        name: 'Pharmacy ',
        selected: false,
        community: 50
    },
    {
        name: 'Zongo ',
        selected: false,
        community: 18
    },
    {
        name: 'Provision Shop ',
        selected: false,
        community: 7
    },
    {
        name: 'Fitness ',
        selected: false,
        community: 82
    },
    {
        name: 'Nurses ',
        selected: false,
        community: 44
    },
    {
        name: 'Hotels & Guest Houses ',
        selected: false,
        community: 15
    },
    {
        name: 'Police ',
        selected: false,
        community: 13
    },
    {
        name: 'Barbers ',
        selected: false,
        community: 43
    },
    {
        name: 'Immigration ',
        selected: false,
        community: 92
    },
    {
        name: 'Kantamanto ',
        selected: false,
        community: 76
    },
    {
        name: 'Fire ',
        selected: false,
        community: 34
    },
    {
        name: 'Washing Bay ',
        selected: false,
        community: 77
    },
    {
        name: 'Military ',
        selected: false,
        community: 21
    },
    {
        name: 'Disability',
        selected: false,
        community: 46
    },
    {
        name: 'Forestry ',
        selected: false,
        community: 99
    },
    {
        name: 'Chop Bar ',
        selected: false,
        community: 89
    },
    {
        name: 'Prisons ',
        selected: false,
        community: 23
    },
    {
        name: 'Masons ',
        selected: false,
        community: 24
    },
    {
        name: 'Customs ',
        selected: false,
        community: 40
    },
    {
        name: 'Plumbers ',
        selected: false,
        community: 85
    },
    {
        name: 'Savings & Loans ',
        selected: false,
        community: 61
    },
    {
        name: 'Auto Mechanic ',
        selected: false,
        community: 30
    },
    {
        name: 'Banking ',
        selected: false,
        community: 68
    },
    {
        name: 'Vulcanizers ',
        selected: false,
        community: 79
    },
    {
        name: 'Insurance ',
        selected: false,
        community: 67
    },
    {
        name: 'Dress Makers ',
        selected: false,
        community: 10
    },
    {
        name: 'Auditors ',
        selected: false,
        community: 56
    },
    {
        name: 'Hair Dressers ',
        selected: false,
        community: 47
    },
    {
        name: 'Susu ',
        selected: false,
        community: 88
    },
    {
        name: 'Aboboyaa ',
        selected: false,
        community: 17
    },
    {
        name: 'Micro Finance ',
        selected: false,
        community: 29
    },
    {
        name: 'Mobile Money Vendors ',
        selected: false,
        community:14
    },
    {
        name: 'Teachers ',
        selected: false,
        community: 83
    },
    {
        name: 'Electricians ',
        selected: false,
        community: 71
    },
    {
        name: 'University ',
        selected: false,
        community: 74
    },
    {
        name: 'NGO ',
        selected: false,
        community: 54
    },
    {
        name: 'Polytechnic ',
        selected: false,
        community: 35
    },
    {
        name: 'Old Students ',
        selected: false,
        community: 25
    },
    {
        name: 'Training Colleges ',
        selected: false,
        community: 33
    },
    {
        name: 'Jewellers ',
        selected: false,
        community: 73
    },
    {
        name: 'Fisher Folks ',
        selected: false,
        community: 19
    },
    {
        name: 'CEO’s ',
        selected: false,
        community: 95
    },
    {
        name: 'Farmers ',
        selected: false,
        community: 6
    },
    {
        name: 'Private Security ',
        selected: false,
        community: 72
    },
    {
        name: 'Painters ',
        selected: false,
        community: 51
    },
    {
        name: 'Harbor ',
        selected: false,
        community: 27
    },
    {
        name: 'Veterinary ',
        selected: false,
        community: 28
    },
    {
        name: 'Borga',
        selected: false,
        community: 84
    },
    {
        name: 'The Utilities ',
        selected: false,
        community: 39
    },
    {
        name: 'Tilers ',
        selected: false,
        community: 2
    },
    {
        name: 'Golf ',
        selected: false,
        community: 64
    },
    {
        name: 'Laborers ',
        selected: false,
        community: 63
    },
    {
        name: 'Football Fans',
        selected: false,
        community: 49
    },
    {
        name: 'Sanitation',
        selected: false,
        community: 8
    },
    {
        name: 'Journalists',
        selected: false,
        community: 11
    },
    {
        name: 'Pensioners',
        selected: false,
        community: 31
    },
    {
        name: 'Carpenters',
        selected: false,
        community: 96
    },
    {
        name: 'Restaurants',
        selected: false,
        community: 70
    },
    {
        name: 'Civil Servants',
        selected: false,
        community: 97
    },
    {
        name: 'Car Garages',
        selected: false,
        community: 52
    },
    {
        name: 'Bakers',
        selected: false,
        community: 32
    },
    {
        name: 'Corporate Ghana',
        selected: false,
        community: 81
    },
    {
        name: 'Mining',
        selected: false,
        community: 9
    },
    {
        name: 'Hawkers',
        selected: false,
        community: 65
    },
    {
        name: 'Truck Drivers',
        selected: false,
        community: 42
    },
    {
        name: 'Kayayei',
        selected: false,
        community: 37
    },
    {
        name: 'Filling Stations',
        selected: false,
        community: 57
    },
    {
        name: 'Market',
        selected: false,
        community: 38
    },
    {
        name: 'Factory Workers',
        selected: false,
        community: 36
    },
    {
        name: 'Entrepreneurs',
        selected: false,
        community: 48
    },
    {
        name: 'National Service',
        selected: false,
        community: 93
    },
    {
        name: 'Photographers',
        selected: false,
        community: 86
    },
    {
        name: 'Import & Export',
        selected: false,
        community: 60
    },
    {
        name: 'Cold Store',
        selected: false,
        community: 26
    },
    {
        name: 'Manufacturers',
        selected: false,
        community: 12
    },
    {
        name: 'December Born',
        selected: false,
        community: 91
    },
    {
        name: 'Airport',
        selected: false,
        community: 75
    },
    {
        name: 'January Born',
        selected: false,
        community: 98
    },
    {
        name: 'Ride Hailing',
        selected: false,
        community: 94
    },
    {
        name: 'February Born',
        selected: false,
        community: 87
    },
    {
        name: 'Okada',
        selected: false,
        community: 62
    },
    {
        name: 'March Born',
        selected: false,
        community: 55
    },
    {
        name: 'Trotro',
        selected: false,
        community: 4
    },
    {
        name: 'April Born ',
        selected: false,
        community: 22
    },
    {
        name: 'Taxi ',
        selected: false,
        community: 100
    },
    {
        name: 'May Born ',
        selected: false,
        community: 53
    },
    {
        name: 'Legal Services ',
        selected: false,
        community: 5
    },
    {
        name: 'June Borns',
        selected: false,
        community: 3
    },
    {
        name: 'Okaishie ',
        selected: false,
        community: 45
    },
    {
        name: 'July Borns ',
        selected: false,
        community: 1
    },
    {
        name: 'Kejetia ',
        selected: false,
        community: 80
    },
    {
        name: 'August Born ',
        selected: false,
        community: 58
    },
    {
        name: 'Makola ',
        selected: false,
        community: 90
    },
    {
        name: 'September Born ',
        selected: false,
        community: 16
    },
    {
        name: 'Welders ',
        selected: false,
        community: 20
    },
    {
        name: 'October Born ',
        selected: false,
        community: 59
    },
    {
        name: 'Abossey Okai ',
        selected: false,
        community: 69
    },
    {
        name: 'November Born ',
        selected: false,
        community: 66
    }
  ];
  randomNumber = undefined;
  selectedCommunity = undefined;

  constructor(){
    this.communityObjects = this.communityObjects.map(obj => {
      let range = null;
      if (obj.community !== null) {
        if(obj.community === 1){
          range = [1,999];
        }else{
          range = [(obj.community - 1)  * 1000, ((obj.community - 1)) * 1000 + 999];
        }
      }
      return {
        ...obj,
        range
      };
    });
  }

  getRandomNumberBetween(min, max) {
   // console.log(min,max);
    const rand = Math.floor(Math.random() * (max - min + 1)) + min;
    if( max <= 9999){
    //  console.log(this.randomNumber);
      this.randomNumber = String(rand).padStart(5, '0');
    }else{
      this.randomNumber = rand;
    }

  }

}

