import { PlayButtonComponent } from './apps/theluckiestafrica/components/play-button/play-button.component';
import { AppInterceptor } from './httpInterceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, enableProdMode } from '@angular/core';
import {ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AutoFocusDirective } from './directives/autofocus/autofocus.directive';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { SafePipe } from './pipes/safe.pipe';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { initializeApp } from 'firebase/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFirebaseApp } from '@angular/fire/app';
import { environment } from 'src/environments/environment.prod';
import { OneMultipleValidatorDirective } from './directives/one-multiple-validator.directive';
import { TicketsService } from './apps/theluckiestafrica/services/tickets.service';
enableProdMode();
@NgModule({
  declarations: [AppComponent, SafePipe, OneMultipleValidatorDirective, AutoFocusDirective],
  exports: [AutoFocusDirective],
  entryComponents: [],
  imports: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot({mode:'ios'}),
    AppRoutingModule,
    BrowserAnimationsModule,
    ComponentsModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy},AuthGuardService,SafePipe,
    TicketsService,
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
