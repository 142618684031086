import { Inject, Injectable } from '@angular/core';
import { Config } from 'src/app/apps/jama/config';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  showLocationPopup: any;
  showSearchPopup: any;
  showCategoryPopup: any;
  mobileSearchExtend = false;
  constructor(@Inject(Config) public config: Config) { }
  }



