/* eslint-disable quote-props */
import { MenuController, ModalController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { CrudService } from './../../../services/crud/crud.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionInterface } from './session.interface';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class LuckiestAuthService {



  sessionVars: SessionInterface;

  baseUrl = environment.app.apiurl+'/users';
  acBaseUrl = environment.app.apiurl+'/account';

  constructor(
    private httpClient: HttpClient,
    private crud: CrudService,
    private router: Router,
    private menuCtrl: MenuController,
    private modalCtrl: ModalController,
    private toastCtrl: ToastController
     ) {
    this.crud.localGet('sessionVars').then(result=>{
      if(result != null){
        this.sessionVars = result;
      }
    //  console.log(this.sessionVars);
    });
  }

   login(mobileNumber,password,countryCode){
   const payload = { mobileNumber, password,countryCode };
   return this.httpClient.post(this.baseUrl+'/authenticate',payload);
  }

  register(name,email,mobileNumber,password,otp,countryCode){
    const payload = {name,email, mobileNumber, password ,otp,countryCode};
    return this.httpClient.post(this.baseUrl+'/register',payload);
   }

   getRegisterOTP(mobileNumber,email,countryCode){
    const payload = {mobileNumber,email,countryCode};
    return this.httpClient.post(this.baseUrl+'/registration-otp',payload);
   }

   deleteAccount(mobileNumber,email){
    const payload = {mobileNumber,email};
    return this.httpClient.delete(this.baseUrl+'/delete-user',{body: payload});
   }




   sendPassResetOTP(mobileNumber){
   const payload = { mobileNumber };
   return this.httpClient.post(this.acBaseUrl+'/otp',payload);
   }

   confirmResetOTP(mobileNumber,otp){
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return this.httpClient.get(this.acBaseUrl+'/otp',{params:{'MobileNumber': mobileNumber,otp}});
   }

   resetPassword(mobileNumber,password){
    const payload = { mobileNumber, password };
    return this.httpClient.post(this.acBaseUrl+'/reset-password',payload);
   }

  logout(){
    this.crud.localDelete('sessionVars');
    this.sessionVars = null;
    this.menuCtrl.enable(false);
    this.modalCtrl.getTop().then(res=>{
      if(res){
      this.modalCtrl.dismiss();
      }
    });
    this.toast('You have logged out successfully','success',4000);
    this.router.navigateByUrl('/');
  }


 async toast(text,color,duraTion){
   const toast = await this.toastCtrl.create({
  header:text,
  color,
  duration:duraTion,
  position: 'top',
  icon:'crescent'
   });

   toast.present();
 }



}
