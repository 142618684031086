import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-select-flag',
  templateUrl: './select-flag.component.html',
  styleUrls: ['./select-flag.component.scss'],
})
export class SelectFlagComponent implements OnInit {

  countries: any = [];
  flagsRootUrl = 'https://ipgeolocation.io/static/flags/';
  constructor(private modalCtrl: ModalController) {
    this.countries = [
      {name:'Ghana',code:'+233',flag:'gh'},
      {name:'Nigeria',code:'+223',flag:'ng'},
      {name:'DR Congo',code:'+233',flag:'cd'},
      {name:'South Africa',code:'+233',flag:'za'},
      {name:'Cote D\'Ivoire',code:'+233',flag:'ci'},
      {name:'Kenya',code:'+233',flag:'ke'}
    ];
  }

  ngOnInit() {}

  selectCountry(country) {
   this.modalCtrl.dismiss(country);
  }

}
