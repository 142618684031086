import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { CountryInfo } from './countriesInfo';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  locationsList: any;
  ipAddress: any;
  currentLocation: any;
  constructor(private location: CountryInfo,private http: HttpClient) {
    this.locationsList = this.location.list;
   }


  getLocations(page?: number, size?: number){
    let locations = [];

    this.locationsList.forEach(loc => {

     locations.push(loc.dial_code+'-'+loc.name+'-'+loc.code);

      });


   locations = locations.slice((page - 1) * size, ((page - 1) * size) + size);

   return locations;
  }



  getLocationsAsync(page?: number, size?: number, timeout = 2000): Observable<any> {
    return new Observable<any>(observer => {
      observer.next(this.getLocations(page, size));
      observer.complete();
    }).pipe(delay(timeout));
  }


  getLocation(): void{
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position)=>{
          console.log(position);;
          const longitude = position.coords.longitude;
          const latitude = position.coords.latitude;
         // this.callApi(longitude, latitude);
        });
    } else {
       console.log('No support for geolocation');
    }
   }


   geoGetIp(){
    return this.http.get('https://api.ipify.org/?format=json');
   }

   getGeoLocation(): Promise<any> {
         return this.http.get('https://api.ipgeolocation.io/ipgeo?apiKey=396f991a1d384f188851874d6f0d957f')
         .toPromise()
         .then(
           (response: []) => Promise.resolve(response)
         .catch((error) => Promise.resolve(error.json()))
         );
 }


  callApi(longitude: number, latitude: number){
    const url = `https://api-adresse.data.gouv.fr/reverse/?lon=${longitude}&lat=${latitude}`;
    //Call API
  }
}
