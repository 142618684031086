import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-waitlist-form',
  templateUrl: './waitlist-form.component.html',
  styleUrls: ['./waitlist-form.component.scss'],
})
export class WaitlistFormComponent implements OnInit {

   formUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSfH7QO4oXQLnJ1o4VVF170Bs7CfwrrCNOlFv5y_4jHROglaUw/viewform?embedded=true';
   sanitizedurl: SafeResourceUrl = '';

  constructor(private sanitizer: DomSanitizer, private modalCtrl: ModalController) { }

  ngOnInit() {
    this.getFormUrl();
  }

  getFormUrl() {
    this.sanitizedurl = this.sanitizer.bypassSecurityTrustResourceUrl(this.formUrl);
  }

  closeModal(){
    this.modalCtrl.dismiss();
  }

}
