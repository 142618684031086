import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Network } from '@capacitor/network';
import { ViewService } from 'src/app/services/view/view.service';

@Component({
  selector: 'app-nothing-to-see',
  templateUrl: './nothing-to-see.component.html',
  styleUrls: ['./nothing-to-see.component.scss'],
})
export class NothingToSeeComponent implements OnInit {

  @Output() retry: EventEmitter<any> = new EventEmitter();
  @Output() waitLoadingOver: EventEmitter<any> = new EventEmitter();
  @Output() networkError: EventEmitter<any> = new EventEmitter();
  @Output() addItemAction: EventEmitter<any> = new EventEmitter();

  @Input() public img;
  @Input() public text;
  @Input() public showImg;
  @Input() public icon;
  @Input() public showIcon;
  @Input() public list;
  @Input() public loadSpinner;
  @Input() public stopLoadWithMessage;
  @Input() public actionButtonText = null;
  loading: boolean;
  waitLoading = true;


  constructor(public viewService: ViewService,private router: Router) {}

  ngOnInit() {
    /*this.loading = true;
    setTimeout(() => {
      this.loading = false;
    },3000)*/
    this.loading = true;
    if (this.viewService.networkStatus === true && this.list.length < 1) {
      if (this.list.length < 1 && this.viewService.networkStatus.connected) {
        setTimeout(() => {
          this.waitLoading = false;
          this.waitLoadingOver.emit(true);
          console.log();
          this.loading = false;
        }, 2000);
      }
    }



    // console.log(this.viewService.networkStatus.connected);

    Network.addListener('networkStatusChange', (status) => {
      console.log(status);

      if (status.connected === true) {
        this.retry.emit(true);
        //console.log(this.router.url);
        //this.router.navigateByUrl('redirector/redirect?url='+this.router.url);
        this.waitLoading = true;
        this.loading = true;
      } else {
        console.log('network error');
        this.networkError.emit(true);
      }
    });
  }

  emitItemAction(){
    this.addItemAction.emit(true);
  }
}
