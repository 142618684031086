import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {



  transform(value: any, format: 'long' | 'short'): any {

    if (value) {

        const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);

        if (seconds < 29) // less than 30 seconds ago will show as 'Just now'

            {return 'Just now';}
            let intervals = {};
      if(format === 'long'){
         intervals = {

            year: 31536000,

            month: 2592000,

            week: 604800,

            day: 86400,

            hour: 3600,

            minute: 60,

            seconds: 1

        }; }
        else{
         intervals = {

            yr: 31536000,

            mth: 2592000,

            w: 604800,

            d: 86400,

            hr: 3600,

            m: 60,

            s: 1

         };
        }

        let counter;

        for (const i in intervals) {
           if(intervals){
            counter = Math.floor(seconds / intervals[i]);
            const pluralSuffix  = counter > 1 ? 's' : '';
            if (counter > 0)
                    {return counter + ' ' + i+pluralSuffix;} // singular (1 day ago)
             }
          }
      }

    return value;

   }

}
