import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { VendorHomePageRoutingModule } from './vendor-home-routing.module';

import { VendorHomePage } from './vendor-home.page';
import { ComponentsModule } from 'src/app/components/components.module';
import { LuckiestComponentsModule } from 'src/app/apps/theluckiestafrica/luckiestComponents.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ComponentsModule,
    LuckiestComponentsModule,
    VendorHomePageRoutingModule
  ],
  declarations: [VendorHomePage]
})
export class VendorHomePageModule {}
