import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appOneMultipleValidator]'
})
export class OneMultipleValidatorDirective {

  private regex = new RegExp(/^\d+$/);

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    const initialValue = inputElement.value;

    // Remove any non-numeric characters
    const newValue = initialValue.replace(/\D/g, '');

    // If the new value matches the whole number pattern, update the input value
    if (this.regex.test(newValue)) {
      inputElement.value = newValue;
    } else {
      // If the new value does not match the pattern, revert to the initial value
      inputElement.value = initialValue;
    }

    // Emit the 'input' event to update the model (required for ngModel two-way binding)
    inputElement.dispatchEvent(new Event('input'));
  }
 }
