import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SvgLoaderService {
  isLoadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoadingSubject.asObservable();

  startLoading() {
    this.isLoadingSubject.next(true);
  }

  endLoading() {
    this.isLoadingSubject.next(false);
  }
}
