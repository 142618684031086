import { LuckiestAuthService } from './../../apps/theluckiestafrica/services/luckiest-auth.service';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { CrudService } from '../crud/crud.service';
import { ViewService } from '../view/view.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  authState: boolean;

  constructor(
    private auth: LuckiestAuthService,
    //private modalCtrl:ModalController,
    private router: Router,
    private crud: CrudService,
    private viewService: ViewService
    ) {}


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> | boolean {
   return new Promise((resolve,reject)=>{
    this.crud.localGet('sessionVars').then((session) => {
      if(session){
        this.auth.sessionVars = session;
        resolve(true);
      }else{
        resolve(false);
        this.viewService.initlogin();
      }
    }).catch((err) => {
        reject(err);
       });
     });
  }


  checkUser(user) {

     if(!user){
       return false;
     }
     return true;

  }



}
