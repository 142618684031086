import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appAutoFocus]',
})
export class AutoFocusDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    const inputValue = inputElement.value;
    console.log(inputValue);

    if (inputValue.length === 1) {
      const nextInput = inputElement.nextElementSibling;
      if (nextInput) {
        (nextInput as HTMLInputElement).focus();
      }
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    const inputValue = inputElement.value;

    if (event.key === 'Backspace' && inputValue.length === 0) {
      const prevInput = inputElement.previousElementSibling;
      if (prevInput) {
        (prevInput as HTMLInputElement).focus();
      }
    }
  }
}
