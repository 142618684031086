import { LuckiestAuthService } from './../../apps/theluckiestafrica/services/luckiest-auth.service';
import { TicketGeneratorComponent } from './../../apps/theluckiestafrica/components/ticket-generator/ticket-generator.component';
import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LoadingController, MenuController, ModalController } from '@ionic/angular';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SignupComponent } from 'src/app/components/signup/signup.component';
import { environment } from 'src/environments/environment';
import { InitPayComponent } from 'src/app/apps/theluckiestafrica/components/init-pay/init-pay.component';


@Injectable({
  providedIn: 'root',
})
export class ViewService {
  sidebarVisible = true;
  showDropDownMenu = false;
  displayPageProgressor = false;
  networkStatus: any;
  showConnectionStatus: boolean;
  pageProgressorColor = 'primary';
  networkError: boolean;
  pageTitle: string;
  pageType: 'admin' | 'front-end' | 'blocked-for-action' = null;
  showCelebrationConffeti = false;
  selectedCommunity: any = null;

  showVerificationPopup: any = false;
  verificationPopupState: any = {
    action: 'input-number',
    newPhoneNumber: null,
    phoneNumber: null,
    phoneVerificationCode: null,
  };

  notificationObject: any = {
    text: 'this is a notification example',
    color: 'success',
    state: false,
  };

  quickReauthOption: any;
  passedMessageData: any = {};
  quickReauthTitle: any;
  avatar = '/assets/default-avatar.jpg';
  showMobileFooterMenu = true;


  showCallerSheet: any = false;
  sellerToCall = null;

  productFocusCall: any = null;
  routerInProcess = false;

  galleryImages = [];

  showCustomerService: any = false;
  showCallToSellModal: any = false;

  showContactModal = false;
  showAboutUsModal = false;
  drawinprogress = true;

  isMobile: boolean;

  constructor(
    public menuCtrl: MenuController,
    public router: Router,
    public appRouteTitle: Title,
    public loadCtrl: LoadingController,
    public deviceService: DeviceDetectorService,
    public modalCtrl: ModalController,
    private luckAuth: LuckiestAuthService,
    public  deviceDetector: DeviceDetectorService
  ) {
    // if(this.sidebarVisible === false){
    //  setTimeout(() => { menuCtrl.enable(false, 'main'); menuCtrl.close('main');}, 10);
    // }else{
    //   menuCtrl.enable(true, 'main');
    // }

   // this.initlogin();
   this.isMobile = this.deviceDetector.isMobile();

  }

  initVerificationPopup(
    popupState,
    currentNumber,
    change = null,
    message = null
  ) {
    this.showVerificationPopup = true;
    this.verificationPopupState.action = popupState.action;
    this.verificationPopupState.phoneNumber = currentNumber;
    this.verificationPopupState.isChange = change;
    this.verificationPopupState.message = message;
  }

  openMenu() {
    this.menuCtrl.enable(true, 'main');
    this.menuCtrl.open('main');
    this.sidebarVisible = true;
  }

  closeMenu() {
    this.menuCtrl.enable(false);
    //this.menuCtrl.close();
    this.sidebarVisible = false;
  }

  toggle() {
    this.menuCtrl.toggle('main-content');
  }

  startPageProgressor() {
    this.displayPageProgressor = true;
  }

  endPageProgressor(timeout) {
    setTimeout(() => {
      this.displayPageProgressor = false;
    }, timeout);
  }

  goTo(url) {
    this.router.navigateByUrl(url);
  }

  setAppRouteTitle(title) {
    this.appRouteTitle.setTitle(title);
  }

  setPageTitle(title) {
    this.pageTitle = title;
  }

  setPageType(type) {

    this.pageType = type;
    if (this.pageType === 'front-end' || this.pageType === 'admin') {

  if(!this.deviceService.isMobile()){
    console.log('opened Menu');

        this.openMenu();
      }
    } else {
      this.closeMenu();
    }
  }

  setViewTitle(title) {
    this.setAppRouteTitle(environment.app.name + ' | ' + title);
    this.setPageTitle(title);
  }

  notify(color, text, timeout) {
    this.notificationObject.state = true;
    this.notificationObject.text = text;
    this.notificationObject.color = color;
    setTimeout(() => {
      this.notificationObject.state = false;
    }, timeout);
  }

  async loadPage(time, until) {
    const loader = await this.loadCtrl.create({
      animated: true,
      showBackdrop: true,
      //duration:5000,
      message: 'Please wait',
      mode: 'ios',
      spinner: 'circular',
      translucent: false,
      cssClass: 'page-loader',
    });
    loader.present();

    if (until) {
      if (until > 0) {
        setTimeout(() => {
          this.dismissLoader();
        }, 3000);
      }
    } else {
      setTimeout(() => {
        this.dismissLoader();
      }, time);
    }
  }

  dismissLoader() {
    this.loadCtrl.getTop().then((res) => {
      if (res !== undefined) {
        this.loadCtrl.dismiss();
      }
    });
  }

 async initlogin(leadingTo = null){
   const modal  = await this.modalCtrl.create({
      component: SignupComponent,
      cssClass: 'signin-modal',
      componentProps:{leadingTo},
      backdropDismiss:true,
      keyboardClose: false,
      showBackdrop: true,
      initialBreakpoint:1.0
   });

   modal.onDidDismiss().then(res=>{
    if(res.data === 'purchase-tickets'){
        this.loadPageForTicket(0);
      }
   });

   modal.present();
   //this.quickReauthOption = 'phone-login';
   //this.quickReauthTitle = 'Sign In';
  }

  async initTicketGenerator(step, fromMegaMenu?: boolean){

    if(!this.luckAuth.sessionVars){
      this.initlogin();
      return;
    }

    this.router.navigateByUrl('/');
    const modal = await this.modalCtrl.create({
      component:TicketGeneratorComponent,
      componentProps:{step, fromMegaMenu},
      cssClass:'LoginModal',
      initialBreakpoint:1.0
    });

    modal.onDidDismiss().then((data: any)=>{
      if(data && data.data && data.data.step === null){
        this.router.navigateByUrl('/tickets');
      }else{
        if(data && data.data){
          this.loadPageForTicket(data.data.step);
        }
      }
    });

   modal.present();
  }

  async loadPageForTicket(step){
    if(!step){
      step = 0;
    }
    //  step = 6;
    const load = await this.loadCtrl.create(
      {spinner:'crescent',duration:1000}
      );

      load.onDidDismiss().then(res=>{
        if(step === 5){
          this.initPayment();
        }else{
          this.initTicketGenerator(step);
        }

      });

      if(this.luckAuth.sessionVars){
        load.present();
      }else{
        this.initlogin();
      }
    }


    async initPayment(){
      const modal  = await this.modalCtrl.create({
         component: InitPayComponent,
         cssClass: 'signin-modal',
         componentProps:{},
         backdropDismiss:true,
         keyboardClose: false,
         showBackdrop: true,
         initialBreakpoint:1.0
      });
      modal.onDidDismiss().then((res: any)=>{
       if(res.data === true){
        // this.loadPageForTicket(6);
       }else{

       }
      });
       modal.present();
      //this.quickReauthOption = 'phone-login';
      //this.quickReauthTitle = 'Sign In';
     }


}
