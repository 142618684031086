import { HomePageModule } from './pages/home/home.module';
import { VendorHomePageModule } from './pages/vendor-home/vendor-home.module';

 const appConfig = {
  mainPortal:{
  key: 'main-portal',
  homepage: HomePageModule
  },
  vendorPortal:{
    key:'vendor-portal',
  homePage: VendorHomePageModule
  },
  adminPortal: {
  key:'admin-portal',
  homePage: VendorHomePageModule
  },
};

export const activeApp = appConfig.mainPortal;
