/* eslint-disable @typescript-eslint/naming-convention */
import { TicketsService } from './tickets.service';
import { LuckiestAuthService } from './luckiest-auth.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { CrudService } from 'src/app/services/crud/crud.service';
import { Observable, of } from 'rxjs';
import { LocationService } from 'src/app/services/location/location.service';
import { UtilService } from 'src/app/services/util/util.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  baseUrl = environment.app.apiurl+'/payments';
  //paystasckPublicKey = 'pk_test_7afea3ad9735c98ec12cf3409336b975231f2217';
  paystasckPublicKey = 'pk_live_a254a7e6f7c66e1dbbd5c3e202cff939b1085f10';
  paystackTrackingToken;
  selectedPayMethod: 'hubtel' | 'paystack' = 'hubtel';

  constructor(private httpClient: HttpClient,
    private luckAuth: LuckiestAuthService,
    private ticketService: TicketsService,
    private crudService: CrudService,
    private locationService: LocationService,
    private utilService: UtilService
    ) {
  }

  setPayMethod(payMethod){
    this.selectedPayMethod = payMethod;
    this.crudService.localInsert('selectedPayMethod',{value:payMethod});
  }


  initiateCheckout(receipientCountryCode,currentLocation){
    const ticket = this.ticketService.ticketData.ticketValue.split('-').join('');
    const payload: any = {
      // eslint-disable-next-line max-len
      ticketRecipientMobileNumber: this.formatPhoneNumberWithZero(this.ticketService.ticketData.receipientNumber,currentLocation.calling_code),
      MobileNumber:this.luckAuth.sessionVars.mobileNumber,
      totalAmount: this.ticketService.ticketData.ticketAmount,
      returnUrl: environment.domain+'/home/?transaction=success',
      cancellationUrl: environment.domain+'/home/?transaction=failed',
      viewTicketBaseUrl :environment.domain,
      tickets:{[ticket]:this.ticketService.ticketData.ticketAmount},
      PayeeEmail:this.luckAuth.sessionVars.email,
      PayeeName:this.luckAuth.sessionVars.name,
      receipientCountryCode
    };

    const trackingToken = this.utilService.randomString(20);
    return new Promise((resolve,reject)=>{
      this.crudService.localGet('selectedPayMethod').then(res=>{
        console.log(res);
        const paymethod = res && res.value;
        if(paymethod === 'paystack'){
            this.paystackTrackingToken = trackingToken;
            this.crudService.localInsert('payStackToken',trackingToken);
            Object.assign(payload,{payMethod:'paystack',trackingToken});
            resolve(this.httpClient.post(this.baseUrl+'/checkout',payload));
         // }
        }else if(paymethod === 'hubtel' || res == null){
         this.crudService.localDelete('payStackToken');
          delete payload.payMethod;
          delete payload.trackingToken;
          resolve(this.httpClient.post(this.baseUrl+'/checkout',payload));
        }
      });
    });
  }


  checkPaymentStatus(transactionId){
    return this.httpClient.get(this.baseUrl+'/status/'+transactionId);
  }

  updatePayStackTransactionStatus(data){
   return  this.httpClient.post(this.baseUrl+'/paystack-callback/',data);
   //return of({data:{},status:'success'});
  }
  paymentCallBack(payloadIn){
  //   const payloadIn = {
  //     status: true,
  //     message: 'request successful',
  //     data: {
  //         id: 'de18b5dbd0-06288',
  //         amount: 1,
  //         ticketNumber: '06288',
  //         purchasedFor: '233548106773',
  //         vendorName: null,
  //         isVendorSales: false,
  //         vendorCommission: 0,
  //         amountAfterCharges: 1,
  //         charges: 0,
  //         clientReference: 'de18b5dbd0',
  //         fullName: '233548106773',
  //         mobileNumber: '233548106773',
  //         description: 'payment of ticket for user 233548106773',
  //         paymentStatus: 'Success',
  //         transactionId: 'd249bb79a2cb4be3a072630d4b596cc9',
  //         externalTransactionId: 'f711a5382ac94f948b125bbc2d0f1236',
  //         paymentResponseCode: 'Success',
  //         paymentResponseMessage: 'Pending',
  //         channel: 'web',
  //         sessionId: 'f711a5382ac94f948b125bbc2d0f1236',
  //         hash: 'V7k81QkQDohlEVzlEX1qT72Drb7',
  //         createdAt: '2022-09-17T20:26:05.7974427Z',
  //         updatedAt: '2022-09-17T20:30:45.6467573Z'
  //     }
  // };

    const payloadEx = {
      responseCode: payloadIn.data.paymentResponseCode,
      status: payloadIn.status,
      data: {
        checkoutId: payloadIn.data.externalTransactionId,
        salesInvoiceId: payloadIn.data.transactionId,
        clientReference: payloadIn.data.clientReference,
        status:  payloadIn.status,
        amount:  payloadIn.data.amount,
        customerPhoneNumber: payloadIn.data.mobileNumber,
        paymentDetails: {
          mobileMoneyNumber: payloadIn.data.mobileNumber,
          paymentType: payloadIn.data.channel
        },
        description: payloadIn.data.description
      }
    };

    console.log(payloadEx);

    return this.httpClient.post(this.baseUrl+'/callback',payloadEx);
  }


  formatPhoneNumberWithZero(phoneNumber, dialCode) {
    phoneNumber = phoneNumber.toString();
    if(phoneNumber.indexOf('+') > -1){
      return phoneNumber;
    } else if(phoneNumber.indexOf(dialCode) > -1){
      return phoneNumber;
    }else {
    const phoneNumberWithoutZero = phoneNumber.replace(/^0+/, '');
    const cleanDialCode = dialCode.replace(/\D/g, '');
    if (phoneNumberWithoutZero.startsWith(cleanDialCode) || phoneNumberWithoutZero.indexOf(dialCode) >  -1) {
      return phoneNumberWithoutZero;
    } else {
      if(phoneNumberWithoutZero.indexOf(dialCode) >  0){
        return  phoneNumberWithoutZero;
      }else{
        return dialCode + phoneNumberWithoutZero;
      }
    }
   }
  }



}
