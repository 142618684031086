import { CrudService } from 'src/app/services/crud/crud.service';
import { HttpClient } from '@angular/common/http';
import { LuckiestAuthService } from './luckiest-auth.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {

  ticketData: any = {
    ticketValue :null,
    ticketNumber:null,
    ticketAmount: 1,
    receipientNumber : null
  };

  ticketInProcess: any;
  focusTicket: any = {};

  baseUrl = environment.app.apiurl+'/tickets';

  constructor(
     private luckAuth: LuckiestAuthService,
     private httpClient: HttpClient,
     private crudService: CrudService
     ) {

    if(this.luckAuth.sessionVars){
      this.ticketData.receipeientNumber =   this.ticketData.receipeientNumber;

    }

    this.crudService.localGet('ticketInProcess').then(res=>{
      if(res){
        this.ticketInProcess = res;
        this.ticketData = this.ticketInProcess.ticket;
      }
    });
    this.crudService.localGet('ticket').then(res=>{
      if(res){
        this.focusTicket = res;
      }
    });
   }

   getTickets(){
    const url = this.baseUrl+'?MobileNumber='+encodeURIComponent(this.luckAuth.sessionVars.mobileNumber);
    return this.httpClient.get(url);
   }

   getRandom(){
    return this.httpClient.get(this.baseUrl+'/random');
   }

   getTicketCommunity(ticketNumber){
    return this.httpClient.get(`${this.baseUrl}/community-name/${ticketNumber}`);
   }

   vefifyRandomTicket(ticket){
    return this.httpClient.get(this.baseUrl+'/'+ticket+'/verify');
   }

   getTicket(ticket){
    // eslint-disable-next-line quote-props, @typescript-eslint/naming-convention
    return this.httpClient.get(this.baseUrl, {params: {'TicketNumber': ticket}});
   }
}
