import { CrudService } from 'src/app/services/crud/crud.service';
import { ViewService } from 'src/app/services/view/view.service';
import { LuckiestAuthService } from './../../services/luckiest-auth.service';
import { TicketsService } from './../../services/tickets.service';
import { UtilService } from './../../../../services/util/util.service';
import { PhitUser } from './../../../../classes/app/profile/phit-user';
import { ModalController, NavParams } from '@ionic/angular';
import { animate, state, style, transition, trigger, query, stagger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { LocationService } from 'src/app/services/location/location.service';
import { PaymentService } from '../../services/payment.service';
import { InputMaskComponent } from 'src/app/components/input-mask/input-mask.component';
import { CommunityService } from 'src/app/services/communities/communities.services';

@Component({
  selector: 'app-ticket-generator',
  templateUrl: './ticket-generator.component.html',
  styleUrls: ['./ticket-generator.component.scss'],
  animations: [
    trigger('staggerIn', [
      transition('* => *', [
        query(':enter', style({ opacity: 0, transform: `translate3d(100px,0,0)` }), { optional: true }),
        query(':enter', stagger('25ms', [animate('200ms',
         style({ opacity: 1, transform: `translate3d(0,0,0)` }))]), { optional: true })
      ])
    ]),
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateX(100%)', opacity: 0}),
          animate('250ms', style({transform: 'translateX(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateX(0)', opacity: 1}),
          animate('200ms', style({transform: 'translateX(100%)', opacity: 0}))
        ])
      ]
    )
  ]      // end of trigger block
})
export class TicketGeneratorComponent implements OnInit {

  @ViewChild('inputMask') inputMask: InputMaskComponent;

  buttons = [1,2,3,4,5,6,7,8,9,0];
  ticketValue = null;
  ticketAmount = null;
  receipientNumber = null;
  buttonsDisabled =  false;
  loading = false;

  step: any;
  currentTicketBalance = 5000;

  currentPage = 1;
  itemsPerPage = 16;
  paginatedData: any[] = [];
  communityObjects = [];
  pageCount = [];

  ticketSteps = [
    {title: 'Step 0', description: 'Search For Ticket', active:true},
    {title: 'Step 1', description: 'Enter your ticket number', active:false},
    {title: 'Step 2', description: 'Enter Ticket Amount', active:false},
    {title: 'Step 3', description: 'Enter Recipient Phone Number', active:false},
    {title: 'Step 4', description: 'Confirm Ticket', active:false},
    {title: 'Step 5', description: 'Make Payment', active:false},
    {title: 'Step 5', description: 'Ticket Purchased Successfully', active:false},
  ];

  buyForSelf = true;
  buyForAnother = false;

  currentFlag: any = 'https://ipgeolocation.io/static/flags/gh_64.png';
  countryObject: any =   {name:'Ghana',dialCode:'+233',countryCode:'gh',flag:'https://ipgeolocation.io/static/flags/gh_64.png'};
  flagsRootUrl = 'https://ipgeolocation.io/static/flags/';
  dialCode = '';
  countryCode = '';
  communityName = '';
  loadInputMask = false;

  selectedCommunity = undefined;

  constructor(
    private modalCtrl: ModalController,
    private utilService: UtilService,
    private notification: NotificationService,
    private navParams: NavParams,
    public ticketService: TicketsService,
    public luckAuth: LuckiestAuthService,
    public viewService: ViewService,
    private crudService: CrudService,
    public locationService: LocationService,
    public paymentService: PaymentService,
    public communityService: CommunityService
    ) {
      this.communityObjects = this.communityService.communityObjects;
      this.selectedCommunity = this.communityObjects[0];
     }

     selectCommunity(community){
      this.selectedCommunity = community;
      // console.log(this.selectedCommunity);
      this.communityService.getRandomNumberBetween(this.selectedCommunity.range[0], this.selectedCommunity.range[1]);
      this.selectedCommunity.randomNumber = this.communityService.randomNumber;
      this.ticketService.ticketData.ticketValue = this.selectedCommunity.randomNumber;
      this.setTicketValue(this.communityService.randomNumber);
      // console.log(this.ticketService.ticketData.ticketValue);
      this.moveStep(1);
     }

  ngOnInit() {

    this.paginateArray();

    this.crudService.localGet('selectedCountry').then((res: any) => {
      if(res !== null && res !== undefined) {
       this.countryObject = res;
       this.currentFlag = this.countryObject.flag;
       this.dialCode = this.countryObject.dialCode;
       this.countryCode = this.countryObject.countryCode;
      }else{
        this.locationService.getGeoLocation().then((ress: any) => {
          if(ress){
            this.countryObject = ress;
            this.currentFlag = this.countryObject.flag;
            this.dialCode = this.countryObject.dialCode;
            this.countryCode = this.countryObject.countryCode;
            this.crudService.localInsert('selectedCountry',this.countryObject);
          }
        });
      }
     });

    this.crudService.localGet('ticket').then(res=>{
   //  console.log(res);
      if(res){
        this.setTicketValue(res.ticketValue);
        this.ticketService.focusTicket = res;
      }else{
        if(this.ticketService.ticketData.ticketValue){
          this.setTicketValue(this.ticketService.ticketData.ticketValue);
          this.ticketService.ticketData.ticketNumber =  this.ticketService.ticketData.ticketValue;
        }
      }
    });

    this.crudService.localGet('community').then((res)=>{
      if(res !== null){
        this.currentTicketBalance =  res.data && res.data.purchasableAmount || 5000;
        this.communityName = res.communityName;
      }
    });

    this.crudService.localGet('verifiedTicket').then((res)=>{
      //console.log(res);
      if(res !== null){
        //console.log(res);
        this.currentTicketBalance =  res && res.purchasableAmount || 5000;
      }
    });

    //console.log(this.step);
   // this.crudService.localDelete('receipientNumber');
    if(this.step >= 2){
      this.crudService.localGet('receipientNumber').then((res)=>{
        if(res !== null){
          this.ticketService.ticketData.receipientNumber = res.num;
        }if(!this.ticketService.ticketData.receipientNumber){
        this.ticketService.ticketData.receipientNumber =  this.luckAuth.sessionVars.mobileNumber;
        }
      });
     }

    if(this.step < 5){
      //console.log('ticketInProcess',this.ticketService.ticketInProcess);
      this.ticketService.ticketInProcess = {ticket: this.ticketService.ticketData,step:this.step};
      //console.log('ticketInProcess',this.ticketService.ticketInProcess);
      this.crudService.localInsert('ticketInProcess',this.ticketService.ticketInProcess);
      //this.setTicketValue(this.ticketService.ticketInProcess.ticket.ticketValue);
    }else if(this.step === 5){
       this.crudService.localDelete('ticketInProcess');
       this.ticketService.ticketInProcess = {};
    }

    this.step = this.navParams.data.step;
    this.moveStep(this.step);

    const fromMegaMenu =  this.navParams.data.fromMegaMenu;
   // console.log(this.communityService.selectedCommunity);
    if(fromMegaMenu){
      this.selectCommunity(this.communityService.selectedCommunity);
    }else{
      this.selectedCommunity = undefined;
    }


      if(!this.luckAuth.sessionVars){
        this.viewService.initlogin('purchase-tickets');
      }
  }
  setCountry(e){
    //this.countryObject = res;
       this.currentFlag = e.flag;
       this.dialCode = e.dialcode;
       this.countryCode = e.code;
       this.crudService.localInsert('selectedCountry',{name:e.name,flag:e.flag,dialCode:e.dialCode,countryCode:e.code});
   }

  generateTicketNumber(size) {
    this.loading = true;
    const zeros = '0'.repeat(size - 1);
    const x = parseFloat('1' + zeros);
    const y = parseFloat('9' + zeros);
    const confirmationCode = String(Math.floor(x + Math.random() * y));

    setTimeout(() => {
      this.loading = false;
      this.ticketService.ticketData.ticketValue = confirmationCode.split('').join('-');
    }, 3000);
    this.generateRandomTicket();
    return confirmationCode;
}

generateRandomTicket(){
  this.selectedCommunity = undefined;
  this.loading = true;
  this.ticketService.getRandom().subscribe((res: any)=>{
   this.getTicketCommunity(res.data).subscribe((resd: any)=>{
    this.communityName = resd.communityName;
    this.crudService.localInsert('community',resd);
   });
    setTimeout(() => {
    this.loading = false;
    if(res.status === true){
      this.ticketService.ticketData.ticketValue = res.data;
      this.ticketService.ticketData.ticketNumber = res.data;
      this.crudService.localInsert('ticketInProcess',{ticket: this.ticketService.ticketData,step:this.step});
      this.crudService.localInsert('ticket',this.ticketService.ticketData);
    }
      }, 3000);
  });
}

setTicketValue(e){
  const arr = (this.ticketService.ticketData.ticketValue && this.ticketService.ticketData.ticketValue.toString().split('')) || [];
   arr.push(e);
   this.ticketService.ticketData.ticketValue = arr.join('').slice(0,5);
   this.ticketService.ticketData.ticketNumber = (this.ticketService.ticketData.ticketValue.length >
    5 ? this.ticketService.ticketData.ticketValue.substring(0,5) : this.ticketService.ticketData.ticketNumber);
}

veryifyTicket(){
 //const ticket = this.ticketService.ticketData.ticketValue;
 const ticket = this.inputMask.value.slice(0,5);
 this.ticketService.ticketData.ticketValue = ticket;
 this.ticketService.ticketData.ticketNumber = ticket;
 this.setTicketValue(ticket);
//console.log(ticket);
  this.ticketService.vefifyRandomTicket(ticket).subscribe((res: any)=>{
    this.currentTicketBalance = res.data.purchasableAmount || 5000;
    this.crudService.localInsert('verifiedTicket',res.data);
    this.getTicketCommunity(ticket).subscribe((resd: any)=>{
      this.communityName = resd.communityName;
      this.crudService.localInsert('community',resd);
     });
    if(res.status === true){
      this.viewService.notify('success','Ticket Verified Successfully',3000);
      this.nextModal(2);
    }else{
      this.viewService.notify('warning','Invalid Ticket',3000);
    }
  },error=>{
    this.viewService.notify('warning','Failed To Verify Ticket',3000);
  });
}

formatCode(){
  const arr = (this.ticketService.ticketData.ticketValue && this.ticketService.ticketData.ticketValue.toString().split('')) || [];
  if(arr.length > 5){
    this.ticketService.ticketData.ticketValue =  this.ticketService.ticketData.ticketValue.toString().substring(0,5) * 1;
  }
}

restrictInput(){
  // eslint-disable-next-line max-len
  if (!Number.isInteger(this.ticketService.ticketData.ticketAmount) || this.ticketService.ticketData.ticketAmount.toString().split('.').length > 1){
    this.ticketService.ticketData.ticketAmount = Math.round(this.ticketService?.ticketData.ticketAmount * 1);
  }
}

moveStep(index){
 this.ticketSteps.forEach(step => {
    step.active = false;
  });
  this.ticketSteps[index].active = true;
}

nextModal(step){
  setTimeout(() => {
    this.modalCtrl.getTop().then(res=>{
      if(res){
        this.modalCtrl.dismiss({step});
      }
    });
  }, 1000);
}

  discardTicketPurchase(){
    this.crudService.localDelete('ticketInProcess').then(res=>{
      this.crudService.localDelete('ticket');
      this.crudService.localDelete('verifiedTicket');
      this.crudService.localDelete('selectedCountry');
     this.ticketService.ticketInProcess = null;
     this.modalCtrl.dismiss();
     this.nextModal(null);
     this.viewService.notify('success','Ticket Discarded',3000);

    });
  }

  getTicketCommunity(ticket){
    return this.ticketService.getTicketCommunity(ticket);
  }

  saveReceipientNumber(){
    //console.log(this.ticketService.ticketData.receipientNumber);
    this.crudService.localInsert('receipientNumber',{num:this.ticketService.ticketData.receipientNumber});
  }



  paginateArray(): void {
    this.communityObjects = this.communityObjects.filter((communityObject) => communityObject.community).
    sort((a,b) => a.community - b.community);

    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.paginatedData = this.communityObjects.slice(startIndex, endIndex);
    this.pageCount = this.getPages();
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    this.paginateArray();
  }

  getPages(): number[] {
    const pageCount = Math.ceil(this.communityService.communityObjects.length / this.itemsPerPage);
    return Array.from({ length: pageCount }, (_, index) => index + 1);
  }

  search(event){
  const evalue = event.detail.value.toLowerCase();
  if(evalue.length){
  const filterResults = this.communityObjects.filter(
    (communityObject) => communityObject.name.replaceAll(' ').toLowerCase().includes(evalue));
    console.log(filterResults);
    this.paginatedData = filterResults;
    this.paginatedData = this.paginatedData.slice(0, 24);
  }else{
   this.communityObjects = this.communityService.communityObjects;
   this.paginateArray();
  }
  }

}
