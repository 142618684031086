import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as uuid from 'uuid';
const myId = uuid.v4();
import { Storage } from '@capacitor/storage';
@Injectable({
  providedIn: 'root',
})
export class CrudService {
  storage: any = Storage;

  constructor() {
    this.storage.keys().then(res=>{
    //  console.log(res);
    });
  }

  localInsert(key, value) {
    //check for data structure
    const structure = this.testDataStructure(value);
    if (structure === 'array' || structure === 'object') {
      value = JSON.stringify(value);
      value = value.replace(/\//g, '\\/');
    }
    return this.storage.set({ key, value });
  }

  localUpdate(key, value) {
    //check for data structure
    const structure = this.testDataStructure(value);
    if (structure === 'array' || structure === 'object') {
      value = JSON.stringify(value);
    }
    return this.storage.set({ key, value });
  }

  localGet(key) {
    return this.storage.get({ key }).then((res) => {
      if(res){
        const structure = this.testDataStructure(res.value);
        if (structure === 'array' || structure === 'object' || typeof(res.value) == 'object') {

       }else if(
        res.value !== null && res.value !== 'null' && res.value !== 'undefined'
         && res.value !== '' &&  typeof(res.value) == 'string'){
           const stringed = res.value.replace(/\//g, '\\/');
           res.value = JSON.parse(stringed);
          }
          return res.value;
      }else{
        return null;
       }
    });
  }

  localDelete(key) {
    return this.storage.remove({ key });
  }

  testDataStructure(object) {
    const stringConstructor = 'test'.constructor;
    const arrayConstructor = [].constructor;
    const objectConstructor = {}.constructor;

    if (object === null) {
      return 'null';
    }
    if (object === undefined) {
      return 'undefined';
    }
    if (object.constructor === stringConstructor) {
      return 'string';
    }
    if (object.constructor === arrayConstructor) {
      return 'array';
    }
    if (object.constructor === objectConstructor) {
      return 'object';
    }
    {
      return 'don\'t know';
    }
  }


}
