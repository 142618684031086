import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { CrudService } from 'src/app/services/crud/crud.service';
import { mergeMap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { LuckiestAuthService } from './apps/theluckiestafrica/services/luckiest-auth.service';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  apiKey: string | null = null;

  constructor(
    private crud: CrudService,
    private router: Router,
    private alertCtrl: AlertController,
    private luckiestUser: LuckiestAuthService
    ) {}

  async init() {
    return await this.crud.localGet('sessionVars');
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.init()).pipe(
      mergeMap(sessionVars => {
        let headers = req.headers
          .set('Content-Type', 'application/json; charset=utf-8')
          .set('Accept', 'application/json, text/plain, */*')
          .set('Access-Control-Allow-Origin', '*')
          .set('Access-Control-Allow-Credentials', 'true')
          .set('Access-Control-Allow-Headers', 'Content-Type')
          .set('Access-Control-Allow-Methods', '*')
          .set('Access-Control-Expose-Headers', 'Content-Disposition')
          .set('Accept-Language', 'en-US,en;q=0.9,fr;q=0.8');

        // eslint-disable-next-line max-len
        // const defaultBearer = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjZjYzNmY2I2NDAzMjc2MGVlYjljMjZmNzdkNDA3YTY5NGM1MmIwZTMiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vcGVwZS1yb2NrcyIsImF1ZCI6InBlcGUtcm9ja3MiLCJhdXRoX3RpbWUiOjE3Mjc2OTk4NDcsInVzZXJfaWQiOiJtUlNXNWVucjI5UWVlZ09IU1oxcGJPVDd3OXcxIiwic3ViIjoibVJTVzVlbnIyOVFlZWdPSFNaMXBiT1Q3dzl3MSIsImlhdCI6MTcyNzcwNjA3OCwiZXhwIjoxNzI3NzA5Njc4LCJlbWFpbCI6ImJ6OWR4NXhxZGVmdHc2ZmZkb2JhcnpueHE2bHN2c25hdnlqYzI0YXF1dG9oQHh5LmNvbSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJiejlkeDV4cWRlZnR3NmZmZG9iYXJ6bnhxNmxzdnNuYXZ5amMyNGFxdXRvaEB4eS5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJwYXNzd29yZCJ9fQ.iX5RC6JdCyt0OWnZmVajxXQz-dmV2Q_BZFzNXyZp_spYYaX_S2ceBT_XkhJtZr_ZpihdVFEVYLlzzLDd2Pu0XxVkPz1tkUNnNdfGViTwe2JEh8PaJCvN69F_DU_D7WbnPlWIXFeZVhG0rtNhDRH7NsZBMox50gqcYnAoyX1rJWsuVdvwCJDJ5lAyyS6Wc6PxgxPu0nhdtytLCdI12TBZDXLHjgGtZ52lp-dhz-mnO6NtjWAV235zHu8zglYtMhW2giz_14xLFW7psjr_PRmm7AHiOCDcMC2WAejsZQJqv_JcLXz9b67kxuwO3Eq6gF7HX8dOWTV9UBOKZiLjMfb6eQ';

        if (sessionVars && sessionVars.token) {
          headers = headers.set('Authorization', `Bearer ${sessionVars.token}`);
        }

        // if (sessionVars && sessionVars.token) {
        //   headers = headers.set('Authorization', `Bearer ${defaultBearer}`);
        // }


        const clonedReq = req.clone({ headers });

        return next.handle(clonedReq).pipe(
          catchError((error: HttpErrorResponse) => {
            console.log(error);
            if (error.status === 401 && error.error === 'Unauthorized') {
              // Handle 401 Unauthorized - Redirect to login
              this.router.navigate(['/']);
              this.showErrorModal();
            }
            return throwError(() => error);
          })
        );
      })
    );
  }

  async showErrorModal(){
   const alert  = await this.alertCtrl.create({
    header:'Session Error',
    message:'Your Session has expired or Something is wrong',
    buttons:[
    {
      text:'Refresh Session Token',
      handler: () => {
      this.luckiestUser.logout();
      }
    },
    {
      text:'Login Again',
      handler: () => {
      this.luckiestUser.logout();
      }
    },

    ]
   });
   alert.present();
  }
}
