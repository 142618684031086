import { Injectable } from '@angular/core';
import { Device, DevicePlugin } from '@capacitor/device';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  utilObject: any;
  device: DevicePlugin = Device;

  constructor(private deviceDetctService: DeviceDetectorService) {}

  async logDeviceInfo() {
    const info = await Device.getInfo();
    return info;
    }

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c)=> {
      // eslint-disable-next-line no-bitwise
      const r = Math.random() * 16 | 0; const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  numToKValue(num) {
    if (num >= 1000000) {
      return `${(num / 1000000).toFixed(0)}M`;
    } else if (num >= 1000) {
      return `${(num / 1000).toFixed(0)}K`;
    } else {
      return num;
    }
  }

  isMobile(){
  return this.deviceDetctService.isMobile();
  }

  buildUrlWithDashes([...elems]) {
    const dashed =  elems.join('-');
    const url =  dashed.toLowerCase().replace(/[^a-z0-9\-_]+/g, '-').replace(/-{2,}/, '-').replace(/^-|-$/, '');
    return url;
  }

  randomString(len) {
    const p = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    // eslint-disable-next-line no-bitwise
    return [...Array(len)].reduce(a=>a+p[~~(Math.random()*p.length)],'');
}

}


