import { ComponentsModule } from 'src/app/components/components.module';
import { SelectFlagComponent } from './components/select-flag/select-flag.component';
import { TicketGeneratorComponent } from './components/ticket-generator/ticket-generator.component';
import { FortuneWheelComponent } from './components/fortune-wheel/fortune-wheel.component';
import { CountdownComponent } from './components/countdown/countdown.component';
import { UniHeaderComponent } from './components/uni-header/uni-header.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { InitPayComponent } from './components/init-pay/init-pay.component';
import { SingleTicketComponent } from './components/single-ticket/single-ticket.component';

@NgModule({
  imports:[
  CommonModule,
  IonicModule,
  FormsModule,
  ReactiveFormsModule,
  ComponentsModule
  ],
  declarations:[
  UniHeaderComponent,
  CountdownComponent,
  FortuneWheelComponent,
  TicketGeneratorComponent,
  SelectFlagComponent,
  InitPayComponent,
  SingleTicketComponent
  ],
  exports:[
    UniHeaderComponent,
    CountdownComponent,
    FortuneWheelComponent,
    SelectFlagComponent,
    InitPayComponent,
    SingleTicketComponent,
    TicketGeneratorComponent,
  ]
})

export class LuckiestComponentsModule{ }
