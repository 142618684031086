import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
})
export class CountdownComponent implements OnInit {

  @ViewChild('cd', { static: false }) counter: any;
  @Input() config: any = {};


  defaultCountDownObject = [
    {
        key: 'days',
        value: [
            '0',
            '0'
        ]
    },
    {
        key: 'hours',
        value: [
            '0',
            '0'
        ]
    },
    {
        key: 'mins',
        value: [
            '0',
            '0'
        ]
    },
    {
        key: 'secs',
        value: [
            '0',
            '0'
        ]
    }
];
  countdownObject: any = [];
  constructor() {
    //this.counter.start();
   }

  ngOnInit() {
   // this.counter.start();

    this.countdownTimer();

  }

  handleEvent($event) {
    console.log('event');
  }

  countdownTimer(){
    const countDownDate = new Date( this.config?.date || 'Dec 24, 2023 00:00:00').getTime();

// Update the count down every 1 second
 const x = setInterval(()=> {

  // Get today's date and time
  const now = new Date().getTime();

  // Find the distance between now and the count down date
  const distance = countDownDate - now;

  // Time calculations for days, hours, minutes and seconds
  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000) *1.0;

  // Display the result in the element with id="demo"
  // document.getElementById('demo').innerHTML = days + 'd ' + hours + 'h '
  // + minutes + 'm ' + seconds + 's ';

  const object = {days, hours, mins: minutes, secs: seconds};
  const objectSplit = Object.entries(object);

  this.countdownObject = [];
  objectSplit.forEach((element: any) => {
   this.countdownObject.push({key:element[0], value : element[1].toString().split('')});

  });
 // console.log(this.countdownObject);
  const dayValue = this.countdownObject[0].value.join('') * 1;

  if(dayValue < 1){
     this.countdownObject = this.defaultCountDownObject;
   }

  // If the count down is finished, write some text
  if (distance < 0) {
    clearInterval(x);
     }
   }, 1000);
  }

}
