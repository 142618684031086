import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { IonSlides, LoadingController, ModalController, Platform, MenuController } from '@ionic/angular';
import { RouteService } from './services/route/route.service';
import { ViewService } from './services/view/view.service';
import { Network } from '@capacitor/network';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { CrudService } from './services/crud/crud.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment.prod';
import { LuckiestAuthService } from './apps/theluckiestafrica/services/luckiest-auth.service';
import { activeApp } from './app-config';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('slideWithNav3', { static: false }) slideWithNav3: IonSlides;
  @ViewChild('mgSlide', { static: false }) mgSlide: IonSlides;
  networkListener: any;
  sliderThree: any = {};
  slideOptsThree = {
    initialSlide: 0,
    slidesPerView: 4,
  };

  systemService = {
   contact:[
    {channel:'whatsapp',name:'Jama247',url:'https://wa.me/+233257304671',logo:'logo-whatsapp'},
    {channel:'phone',name:'Jama247',url:'tel:+233257304671',logo:'call'},
    {channel:'messenger',name:'Jama247',url:'tel:233548106733',logo:'logo-facebook'},

   ]
  };


  sellerSteps: any [] = [
    {title:'Create Your Account on Jama App or Website',
    description:'Create your account and select seller option,',
    action:'login'},
    {title:'Complete Your Profile, Verify Phone Number',
    description:'Complete your Profile by verifying your phone number and/or email address(optional)',
    action:'profile'},
    {title:'Upload the products you want to sell',
    description:'Start uploading your products for no fee.',
    action:'create'},
  ];

  customerServiceProviders: any = [];
  environment = null;
  appMenus: any = [];
  vendorAppMenus: any = [];
  showCountDown =  false;
  domain = window.location.hostname;
  permitAdmin = false;
  activeApp;
  constructor(
    private platform: Platform,
    private routeService: RouteService,
    private modalCtrl: ModalController,
    public viewService: ViewService,
    private loadCtrl: LoadingController,
    private crud: CrudService,
    private sanitizer: DomSanitizer,
    public router: Router,
    public luckiestAuth: LuckiestAuthService,
    private menuCtrl: MenuController
  ) {

   this.activeApp = activeApp;
    this.appMenus = [
      {title:'Home',icon:'home-outline',url:'/home'},
      {title:'My Tickets',icon:'ticket-outline',url:'/tickets'},
      {title:'My Prize Tokens',icon:'ticket-outline',url:'/scratch-cards'},
    ];

    this.vendorAppMenus = [
      {title:'Home',icon:'home-outline',url:'/home'},
      {title:'Vendor Dashboard',icon:'ticket-outline',url:'/vendor-dashboard'},
     //{title:'My Prize Tokens',icon:'ticket-outline',url:'/scratch-cards'},
    ];

    this.environment = environment;
    this.platform.ready().then((res) => {
      setTimeout(() => {
   //     this.prepareData();
      }, 1300);
    });

 //this.viewService.loadPageForTicket(2);

  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event: Event) {
    // Your scroll event handling logic here
    console.log('Scrolling detected!');
    // You can perform any actions you want based on the scroll event.
  }

  toggleMenu(){
    this.menuCtrl.enable(false);
  }

  hideCountDown(){}

  ngOnInit() {
  // this.viewService.initlogin();
    this.routeService.router.events.subscribe(async () => {
      const isModalOpened = await this.modalCtrl.getTop();
      if (isModalOpened) {
        this.modalCtrl.dismiss();
      }
      this.viewService.showDropDownMenu = false;
      const loadOpened = await this.loadCtrl.getTop();
      if (loadOpened) {
        this.loadCtrl.dismiss();
      }
      this.viewService.showCallerSheet = false;
      this.viewService.galleryImages = [];
    });

    this.routeService.router.events.subscribe((event) => {
      logCurrentNetworkStatus();
      this.viewService.startPageProgressor();
      this.viewService.showMobileFooterMenu = true;
      //this.viewService.pageType = undefined;
      if (event instanceof NavigationStart) {
        this.viewService.setViewTitle('');
      }

      if (event instanceof NavigationEnd) {
        this.viewService.endPageProgressor(1200);
        const currentUrl = this.routeService.router.url;
      }
      this.viewService.endPageProgressor(1200);
    });

    const logCurrentNetworkStatus = async () => {
      const status = await Network.getStatus();
      // console.log(status)
      this.viewService.networkStatus = status;

      if (this.viewService.networkStatus.connected === false) {
        this.viewService.showConnectionStatus = true;

        this.viewService.networkError = true;
      }

      if (this.viewService.networkStatus.connected === true) {
        setTimeout(() => {
          this.viewService.showConnectionStatus = false;
        }, 5000);
      }
    };

    logCurrentNetworkStatus();

    this.networkListener = Network.addListener(
      'networkStatusChange',
      (status) => {
        this.viewService.networkStatus = status;
        if (this.viewService.networkStatus.connected === false) {
          this.viewService.showConnectionStatus = true;
        }
        if (this.viewService.networkStatus.connected === true) {
          setTimeout(() => {
            this.viewService.showConnectionStatus = false;
          }, 5000);
        }
      }
    );

     setTimeout(() => {
      const authorisedNumbers = ['+233548106773'];
      this.permitAdmin =  authorisedNumbers?.includes(this.luckiestAuth?.sessionVars?.mobileNumber)
      || this.domain.includes('localhost') ? true : false;
     }, 1000);
  }


  initLogin(){
    this.viewService.initlogin();
    this.viewService.showCallerSheet = false;
  }

  closeContactSheet(){
    this.viewService.showCallerSheet = false;
  }

  slidePrev(object, slideView) {
    slideView.slidePrev(500).then(() => {
      this.checkIfNavDisabled(object, slideView);
    });
  }

  slideNext(object, slideView) {
    slideView.slideNext(500).then(() => {
      this.checkIfNavDisabled(object, slideView);
    });
  }

  //Method called when slide is changed by drag or navigation
  slideDidChange(object, slideView) {
    this.checkIfNavDisabled(object, slideView);
  }

  checkIfNavDisabled(object, slideView) {
    this.checkisBeginning(object, slideView);
    this.checkisEnd(object, slideView);
  }

  checkisBeginning(object, slideView) {
    slideView.isBeginning().then((istrue) => {
      object.isBeginningSlide = istrue;
    });
  }
  checkisEnd(object, slideView) {
    slideView.isEnd().then((istrue) => {
      object.isEndSlide = istrue;
    });
  }

  slideTo(index) {
    this.mgSlide.slideTo(index);
  }

  transformUrl(data) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(data);
  }



}
